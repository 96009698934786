


import { useState, useEffect } from 'react';

const TypingText = ({ sentences, speed, sentenceDelay }) => {
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const currentSentence = sentences[currentSentenceIndex];

    if (currentIndex < currentSentence.length) {
      const timer = setTimeout(() => {
        setCurrentText((prevText) => prevText + currentSentence[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      }, speed);
      return () => clearTimeout(timer);
    } else {
      // Sentence is complete, move to the next one after a delay
      const sentenceTimer = setTimeout(() => {
        setCurrentText('');
        setCurrentIndex(0);
        setCurrentSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
      }, sentenceDelay);
      return () => clearTimeout(sentenceTimer);
    }
  }, [currentIndex, currentSentenceIndex, sentences, speed, sentenceDelay]);

  const cursorClass = currentIndex < currentText.length ? 'blink-cursor' : 'cursor';

  return (
    <span>
      <span className='text-[#1AD05D]'>{currentText}</span>
      <span className={cursorClass}>|</span>
    </span>
  );
};

export default TypingText;
