import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SecondSection = () => {
  return (
    <div className="bg-[#F2F4F8] xl:pb-[240px] xl:pt-[145px] py-[76px] px-[17px]">
      <div className="lg:w-[85%] mx-auto">
        <h3 className="justify-start flex leading-tight  md:text-[3rem] sm:w-[350px] text-[1.75rem] md:w-[606px] font-medium text-[#050B05] text-left">
          Freelancing = flexibility, freedom, fulfillment.
        </h3>

        <div className=" flex flex-col 2xl:flex-row justify-between items-start mt-[64px] md:mt-[100px] gap-[44px] md:gap-[50px] xl:gap-[108px]">
          {/*  1*/}
          <div className=" flex flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
             

<LazyLoadImage 
           src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1711636071/Automate_Your_Trade_2x_bftzbm.png"
           alt=" Automate your trade"
           title=" Automate your trade"
           className="w-full h-full"
      />
              </div>
            </div>

            <div className=" flex flex-col justify-start items-start gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Automate your trade
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                No long process, no middleman. Convert your crypto to cash the
                fastest way possible. Your funds are deposited directly into
                your bank account within minutes.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className=" flex flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image*/}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
           

<LazyLoadImage 
          src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1711635910/Easy_To_Use_2x_tx7faa.png"
          alt=" Easy to use"
          title=" Easy to use"
          className="w-full h-full"
      />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start  gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Easy to use
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                Crane simplifies navigation within the crypto world so you do
                not need to worry about its complexities. Our user-friendly
                interface ensures a seamless experience, whether you're a crypto
                enthusiast or a newbie. Crane is for everyone.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className=" flex flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
            


<LazyLoadImage 
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1711635986/Never_Miss_Out_2x_rucuvx.png"
            alt="Never miss out"
            title="Never miss out"
            className="w-full h-full"
      />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start  gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Never miss out
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                No long process, no middleman. Convert your crypto to cash the
                fastest way possible. Your funds are deposited directly into
                your bank account within minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
