
// import { motion } from "framer-motion";
// import { staggerContainer } from "../../utils/motion";
import Cards from "../Cards";
import { fourCardsData } from "../../constants";
// import Feature from "../Feature";

const FourthSection = () => {
  return (
    <div
      // variants={staggerContainer}
      // initial="hidden"
      // whileInView="show"
      // viewport={{ once: false, amount: 0.25 }}
      className="bg-white  flex flex-col items-center justify-center text-center"
    >
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-1 m-auto justify-evenly xl:w-full  lg:mt-[120px] mt-[50px]">
        {fourCardsData.map(world => (
          <Cards key={world.id} {...world}  />
        ))}
      </div>
    </div>
  );
};

export default FourthSection;