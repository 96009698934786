// import Head from "next/head";

const ComingSoon = () => {
  return (
    <div className="flex justify-center items-center p-4 h-screen">
      {/* <Head>
        <title>{title}</title>
      </Head> */}
      <div className=" text-center">
        <h1 className="text-2xl font-semibold mb-4">Coming Soon</h1>
        <p className="text-gray-600">
          We are working on something awesome! Stay tuned.
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
