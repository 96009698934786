
import { useEffect } from "react";

const Redirect = ({ to }) => {
  useEffect(() => {
    // Redirect to the specified URL when this component is rendered
    window.location.href = to;
  }, [to]); // Dependency array ensures this runs only when 'to' changes

  return null; // This component doesn't render anything
};

export default Redirect;
