import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ThirdSection = () => {
  return (
    <div className=" lg:w-[85%] md:w-11/12 px-8  lg:ml-[5%] xl:ml-[5%] md:pb-[188px] md:pt-[133px] pt-[85px] pb-[99px] overflow-hidden mx-auto">
      <h1 className="text-[#050B05] max-w-[882px] font-extrabold md:text-[3rem] xl:text-[5rem] md:pb-[115px] pb-[32px]  text-[1.75rem] leading-tight text-left pb-[24px] xl:pb-[40px]">
        Why&nbsp;
        <span className="inline-block text-[#419822] italic">Convert</span>
        &nbsp;on Crane?
      </h1>

      <div className="flex flex-col xl:flex-row gap-[32px] md:gap-[40px] pb-[32px] md:pb-[34px]">
        <div className="xl:w-[60%]  w-full bg-gray-200 p-4 asAFreelancer rounded-[60px] border-[#C388F7] border-2 max-h-[460px] px-[32px] py-[36px] md:max-h-[680px] 2xl:pt-[80px] 2xl:pb-[63px] 2xl:pl-[80px] 2xl:pr-[60px]">
          <h3 className="text-[#050B05] font-semibold text-[1.25rem] md:font-bold text-left md:text-[2rem] pb-[12px] md:pb-[48px]">
            As a Freelancer
          </h3>
          <div className="flex xl:flex-row flex-col justify-between items-center gap-[20px]">
            <p className="text-[0.75rem] font-medium text-[#707070] md:text-[1.25rem] text-left leading-relaxed pb-[40px] md:pb-[22px]">
              You can receive your payment from anywhere in the world quickly
              and convert it to your local fiat directly into your bank account.
              Generate payment links and share with your international clients,
              and receive value instantly. Withdraw as high as 10 million naira
              daily.
            </p>

            {/* Image */}
            <div className="flex justify-center items-center mx-auto flex-none w-[179px] h-[180px] md:w-[268px] md:h-[280px] xl:h-[300px]">
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1711381093/3D_Laptop_2x_ogvi1d.png"
                alt="A 3D cartoon computer monitor with a blank screen."
                title="A 3D cartoon computer monitor with a blank screen."
                className="w-full h-full"
              />
            </div>
          </div>
        </div>

        <div className="xl:w-[45%] w-full bg-gray-300 p-4 security rounded-[60px] border-[#BBF1FF] border-2 max-h-[460px] px-[32px] py-[36px] md:max-h-[680px] 2xl:pt-[80px] 2xl:pb-[63px] 2xl:px-[80px]">
          <h3 className="text-[#050B05] font-semibold text-[1.25rem] md:font-bold text-left md:text-[2rem] pb-[12px] md:pb-[48px]">
            Security
          </h3>
          <p className="text-[0.75rem] font-medium text-[#707070] md:text-[1.25rem] text-left leading-relaxed pb-[55px] xl:pb-[22px]">
            Our platform is built with cutting-edge encryption and security
            measures to ensure that your money is protected every step of the
            way.
          </p>
          {/* Image */}
          <div className="flex justify-center items-center mx-auto w-[207px] h-[200px] md:w-[314px]  md:h-[280px] xl:h-[302px]">
            <LazyLoadImage
              src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708703791/3D_Security_2x_vgl7hx.png"
              alt="A blue padlock with gold accents, surrounded by colorful shapes and golden coins, representing security and cryptocurrency."
              title="A blue padlock with gold accents, surrounded by colorful shapes and golden coins, representing security and cryptocurrency."
              className="w-full h-full"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col xl:flex-row gap-[32px] md:gap-[40px]">
        <div className="xl:w-[60%]  w-full bg-gray-200 p-4 fastAndConvenient rounded-[60px] border-[#C0FAA0] border-2 max-h-[460px] px-[32px] py-[36px] md:max-h-[680px] 2xl:pt-[80px] 2xl:pb-[63px] 2xl:pl-[80px] 2xl:pr-[60px]">
          <h3 className="text-[#050B05] font-semibold text-[1.25rem] md:font-bold text-left md:text-[2rem] pb-[12px] md:pb-[48px]">
            Fast and Convenient
          </h3>
          <div className="flex xl:flex-row flex-col justify-between">
            <p className="text-[0.75rem] font-medium text-[#707070] md:text-[1.25rem] text-left leading-relaxed pb-[10px] sm:pb-[30px] md:pb-0 xl:pb-[22px]">
              Convert your crypto to cash in under 100 seconds. We understand
              that time is of the essence when it comes to supporting your loved
              ones. Crane allows you to receive payment from anywhere in the
              world and have it converted to your local currency in the fastest
              way possible.
            </p>

            {/* Image */}
            <div className="flex justify-center items-center mx-auto w-[142px] h-[200px] xl:w-[284px] md:h-[280px] xl:h-[400px] flex-none">
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708703884/3D_Lightning_2x_teoxbq.png"
                alt="A glowing green lightning bolt symbol"
                title="A glowing green lightning bolt symbol"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
        <div className="xl:w-[45%] w-full bg-gray-300 p-4 bonusRewards rounded-[60px] border-[#FFDEDF] border-2 max-h-[460px] px-[32px] py-[36px] md:max-h-[680px] 2xl:pt-[80px] 2xl:pb-[63px] 2xl:px-[80px]">
          <h3 className="text-[#050B05] font-semibold text-[1.25rem] md:font-bold text-left md:text-[2rem] pb-[12px] md:pb-[48px]">
            Bonus rewards
          </h3>
          <p className="text-[0.75rem] font-medium text-[#707070] md:text-[1.25rem] text-left leading-relaxed pb-[60px] md:pb-[22px]">
            We provide you with the most cost-effective market rates from the
            top 10 exchanges.
          </p>
          {/* Image */}
          <div className="flex justify-center items-center mx-auto w-[200px] h-[180px] md:w-[290px] xl:w-[312px] md:h-[250px] xl:h-[281px] ">
            <LazyLoadImage
              src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708703777/3D_Rate_2x_hf0upt.png"
              alt="A 3D rendering of a yellow percentage symbol."
              title="A 3D rendering of a yellow percentage symbol."
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
