import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://usecrane.co/privacy-policy" />
        <title>Product and all round crypto updates and news | Crane App</title>
        <meta
          name="title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
      </Helmet>
      <Navbar
        bgColor="[#BFFBE2]"
        textColor="[#050B05]"
        openModal={openModal}
      />
      <Modal onClose={closeModal} isOpen={isOpen} />
  

      <div className="w-full mx-auto  lg:w-[85%] xl:w-11/12 py-[50px] px-[30px] xl:py-[80px] xl:px-[60px]">
        <h1 className="xl:text-5xl text-3xl font-semibold pb-[10px]">
          Privacy Policy
        </h1>
        <p className="font-medium xl:pb-[80px] pb-[60px]">
          Effective Date: November 11, 2023
        </p>
        <h4 className="font-semibold xl:text-2xl text-xl">DEFINITIONS</h4>
        <div className="xl:p-[30px] py-[20px] px-[10px]">
          <p className="pb-[3px]">
            1. "Parties" means both you and the operator
          </p>
          <p className="pb-[3px]">
            2. "Operator" means Crane by The Big Bird Tech Limited, the owner,
            publisher, and administrator of the application.
          </p>
          <p className="pb-[3px]">
            3. "Personal Data" refers to any personally identifiable information
            collected about you in connection with your use of this service.
          </p>
          <p className="pb-[3px]">
            4. "Service" means the application “Crane”, including all pages,
            subpages, blogs, forums, and other connected internet content
            whatsoever.
          </p>
          <p className="pb-[3px]">
            5. "Products" means the goods and products (both tangible and
            intangible) offered on the application.
          </p>
          <p>6. "Services" means the services offered on the application</p>
          <p className="pb-[3px]">
            7. "Third Party Service Provider" means a party or parties who are
            contracted by the owner or data controller for the purposes of
            processing the personal data of the user
          </p>
          <p className="pb-[3px]">
            8. "User Content" means any audio, video, text, images, or other
            material users choose to display on this application
          </p>
          <p className="pb-[3px]">
            9. "You, Yours" means the user of this application.
          </p>
        </div>
        <h4 className="font-semibold text-2xl">INTRODUCTION</h4>
        <div className="py-[30px]">
          <p className="pb-[5px]">
            When you use this service or purchase the products or services
            listed on the application, we may collect personal information about
            you. This privacy policy is meant to let you know what information
            we collect about you, how we collect it, how we use it, and what
            rights you have in relation to that information.
          </p>
          <p className="">
            We are dedicated to keeping your personal information secure while
            you use this application. You indicate that you have read and agree
            to the terms of the privacy policy by continuing to use our
            application. Also, it signifies that you have approved the use of
            your personal data and accepted the necessary disclosures
          </p>
        </div>
        <h4 className="font-semibold text-2xl">
          THE PERSONAL DATA WE COLLECT FROM YOU
        </h4>
        <div className="py-[30px]">
          <p className="">
            We collect various types of information to enable us to provide a
            good service to all our users. Depending on how our service will be
            used, the different types of personal data we collect are as
            follows:
          </p>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              <b>A. For registered users:</b> During the process of your
              registration, we may collect the following information:
            </p>
            <p>
              Name, Date of Birth, Bank Verification Number, and Mobile Phone
              Number. We may also require other information in relation to:
            </p>
            <div className="xl:p-[30px] py-[20px] px-[10px]">
              <p className="pb-[3px]">
                1. Your interaction with our representatives.
              </p>
              <p className="pb-[3px]">
                2. Receiving notifications by text message or email about
                marketing.
              </p>
              <p className="pb-[3px]">3. Receiving general emails from us.</p>
              <p className="pb-[3px]">
                4. Commenting on our content or other user content on our
                application.
              </p>
              <p className="pb-[3px]">5. The purchases you make.</p>
            </div>

            <p className="pb-[10px]">
              <b>B. For unregistered users:</b> We will collect passive
              information from all registered and unregistered users. These
              pieces of information include cookies, IP address information,
              location information, and certain browser information.
            </p>

            <p className="pb-[10px]">
              <b>C. Sales and billing information:</b> We may collect your
              credit and/or debit card information, which includes your card
              number, password, and other information, as well as your billing
              address, contact address, and other information necessary for your
              purchase.
            </p>

            <p className="pb-[10px]">
              <b>D. User experience:</b> From time to time, we may also request
              certain personal data that may be necessary to improve our service
              and the products and services we offer on the application.
            </p>
          </div>
        </div>

        <h4 className="font-semibold text-2xl">
          THE PERSONAL DATA WE COLLECT AS YOU USE OUR SERVICE
        </h4>
        <p className="pt-[10px]">
          We use the following to collect personal data from you:
        </p>
        <div className="py-[30px]">
          <p className="">
            <b> Cookies:</b> We use the data collected by cookies to offer you
            the best experience on our application. Cookies are information
            stored in your browser when you visit our application or use a
            social network on your PC, smartphone, or tablet. They contain
            various data, which includes the name of the server from which it
            comes, the numeric identifier, etc. The types of cookies we use are
            as follows:
          </p>
          <div className="py-[30px]">
            <div className="xl:px-[30px] px-[10px]">
              <p className="pb-[3px]">
                1.<b>Technical cookies:</b> These cookies are essential for the
                correct functioning of our application and to provide the
                service required by our users.
              </p>
              <p className="pb-[3px]">
                2. <b>Profiling cookies:</b> These cookies are used to create
                user profiles based on personal choices and preferences in order
                to send advertising messages to your device.
              </p>
              <p className="pb-[3px]">
                3. <b>Third-party cookies:</b> While using our service, you may
                receive cookies from applications managed by other
                organisations. Third-party analytical cookies may also be
                installed. Third-party analytical cookies are used to detect
                information on user behaviour on our application. This is placed
                in order to monitor the performance and improve the usability of
                this application.
              </p>
              <p className="pb-[3px]">
                4. <b>Support for configuring your browser:</b> You can manage
                cookies through the settings of your browser on your device.
                However, deleting cookies from your browser may remove the
                preferences you have set for this application.
              </p>
            </div>
          </div>

          <p>
            <b>Log Data:</b> We also use log files, which store automatic
            information collected when users visit this application. The log
            data that may be collected is as follows:
          </p>

          <div className="py-[30px]">
            <div className="xl:px-[30px] px-[10px]">
              <p className="pb-[3px]">
                1. The domain and host from which you access the application.
              </p>
              <p className="pb-[3px]">
                2. Name of the Internet Service Provider. (ISP)
              </p>
              <p className="pb-[3px]">3. Date and time of visit.</p>
              <p className="pb-[3px]">
                4. Your computer operating system and browser software.
              </p>
              <p className="pb-[3px]">5. Internet protocol (IP) address.</p>
            </div>
          </div>

          <h4 className="font-semibold text-2xl">THIRD PARTIES</h4>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              We may utilise third-party service providers from time to time to
              help us process your personal data and help us with our
              application.
            </p>
            <p className="pb-[10px]">
              We share your personal data with third parties to protect our, or
              the users' rights, property, or safety, or as otherwise required
              by law.
            </p>
          </div>

          <h4 className="font-semibold text-2xl">STORAGE OF PERSONAL DATA</h4>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              We take the security of the personal data we collect very
              seriously, and we take reasonable measures to reduce the risk of
              accidental destruction, loss, or unauthorised access to such
              information. However, please note that no system involving the
              transmission of information via electronic storage systems or the
              internet is completely secure.
            </p>
            <p className="pb-[10px]">
              The personal data and any other information we have about you may
              be stored for such a period as we may determine until you
              terminate your account with us or withdraw your consent.
            </p>
          </div>

          <h4 className="font-semibold text-2xl">
            PURPOSE OF PROCESSING PERSONAL DATA
          </h4>
          <div className="py-[30px]">
            <p className="">
              We collect and use the personal data that we collect for the
              following reasons:
            </p>

            <div className="xl:p-[30px] py-[20px] px-[10px]">
              <p className="pb-[3px]">
                1. To provide our service, maintain and make improvements to the
                service we provide to you
              </p>
              <p className="pb-[3px]">
                2. To develop new Products and Services on the application.
              </p>
              <p className="pb-[3px]">
                3. To provide personalised Service to you, including making
                recommendations and providing personalised content.
              </p>
              <p className="pb-[3px]">4. To provide customer service to you.</p>
              <p className="pb-[3px]">
                5. To provide You with updates on the Application and related
                items.
              </p>
              <p className="pb-[3px]">
                6. To provide analytics and measurement to understand how our
                service is used
              </p>
            </div>
          </div>

          <h4 className="font-semibold text-2xl">
            PROTECTION OF PERSONAL DATA
          </h4>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              Our service is built with strong security features that
              continuously protect your personal data. Our security features
              help us detect and block security threats. If we detect any
              security risk, we may inform you and guide you through steps to
              stay protected.
            </p>
          </div>

          <h4 className="font-semibold text-2xl">
            DISCLOSURE OF PERSONAL DATA
          </h4>
          <div className="py-[30px]">
            <p className="">
              We do not disclose your personal data except for any of the
              following reasons:
            </p>

            <div className="xl:p-[30px] py-[20px] px-[10px]">
              <p className="pb-[5px]">
                1. If you have granted us permission to do so, we will disclose
                your personal data where we have received your unequivocal
                consent and permission to do so.
              </p>
              <p className="pb-[5px]">
                2. For the purposes of processing your personal data, we may
                disclose your personal data to our affiliates and other trusted
                businesses or persons for the purpose of processing your
                personal data for us, based on our instructions and in
                compliance with our privacy policy.
              </p>
              <p className="">
                3. If we are legally required to do so, we may also disclose and
                share your personal data for the following reasons:
              </p>

              <p className="xl:px-[30px] px-0 mt-[10px] ">
                <span className="xl:mr-[10px] mr-[5px] ">●</span>
                To meet any applicable law, regulation, legal process or legal
                request, such as subpoenas, court orders, requests for
                administrative or government bodies.
              </p>
              <p className="xl:px-[30px] px-0 mt-[10px] ">
                <span className="xl:mr-[10px] mr-[5px]  ">●</span>
                Enforce our applicable Terms of Use.
              </p>
              <p className="xl:px-[30px] px-0 mt-[10px] ">
                <span className="xl:mr-[10px] mr-[5px]  ">●</span>
                To detect, prevent or address any fraud, security or technical
                issues.
              </p>
              <p className="xl:px-[30px] px-0 mt-[10px] ">
                <span className="xl:mr-[10px] mr-[5px]  ">●</span>
                To prosecute or bring any legal action against any user who has
                violated any law or our Terms of Use.
              </p>

              <p className="pt-[20px]">
                4. Any other reason that may be necessary for the operation of
                our application.
              </p>
            </div>
          </div>

          <h4 className="font-semibold text-2xl">
            USER RIGHTS: ACCESS, CORRECTION, AND DELETION OF PERSONAL DATA
          </h4>
          <p className="pt-[10px]">
            At Crane, we are committed to ensuring the privacy and security of
            your personal data. As a user of Crane, you have certain rights
            concerning the personal information we collect. This clause outlines
            your rights to access, correct, and delete your personal data.
          </p>

          <div className="py-[30px]">
            <div className="xl:px-[30px] px-[10px]">
              <p className="pb-[3px]">
                1.<b> Right to Access:</b> You have the right to request access
                to the personal data we hold about you. To exercise this right,
                please contact us at &nbsp;
                <a
                  // target="_blank"
                  href="mailto:support@usecrane.co"
                  className="underline text-[blue]"
                  // rel="noreferrer"
                >
                  support@usecrane.co
                </a>
                . We will provide you with a copy of the information we have
                within a reasonable timeframe.
              </p>
              <p className="pb-[3px]">
                2. <b>Right to Correction: </b>If you believe that the personal
                data we hold about you is inaccurate or incomplete, you have the
                right to request a correction. You can update certain personal
                information directly within the app. For other corrections,
                please contact us at &nbsp;
                <a
                  // target="_blank"
                  href="mailto:support@usecrane.co"
                  className="underline text-[blue]"
                  // rel="noreferrer"
                >
                  support@usecrane.co
                </a>
                , and we will promptly address your request.
              </p>
              <p className="pb-[3px]">
                3. <b>Right to Deletion:</b> You have the right to request the
                deletion of your personal data from our systems. Please note
                that this right is subject to certain limitations, such as when
                we are required to retain data for legal or legitimate business
                purposes. To request the deletion of your data, please contact
                us at &nbsp;
                <a
                  // target="_blank"
                  href="mailto:support@usecrane.co"
                  className="underline text-[blue]"
                  // rel="noreferrer"
                >
                  support@usecrane.co
                </a>
                .
              </p>
            </div>
          </div>

          <div className="py-[30px]">
            <p className="pb-[10px]">
              <b>How to Exercise Your Rights:</b> To exercise any of the rights
              mentioned above or if you have questions regarding your privacy
              rights, please contact us via email at &nbsp;
              <a
                // target="_blank"
                href="mailto:support@usecrane.co"
                className="underline text-[blue]"
                // rel="noreferrer"
              >
                support@usecrane.co
              </a>
              . We will respond to your request within a reasonable time frame
              and will work diligently to address your concerns.
            </p>

            <p className="pb-[10px]">
              <b>Verification Process: </b> To protect your privacy and
              security, we may need to verify your identity before fulfilling
              your request. We may request additional information to confirm
              your identity and ensure that your personal data is disclosed only
              to you.
            </p>

            <p className="pb-[10px]">
              <b>Updates to this Clause:</b> We may update this clause
              periodically to reflect changes in our privacy practises or
              applicable laws. Any material changes will be communicated to you
              through the app or other appropriate means.
            </p>

            <p className="pb-[10px]">
              By using the Crane app, you acknowledge and agree to the terms
              outlined in this privacy policy, including your rights regarding
              access, correction, and deletion of your personal data.
            </p>
          </div>

          <h4 className="font-semibold text-2xl">USER CONTENT</h4>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              We may allow users to contribute content or information to this
              page. These contents include audio, video, updates, articles,
              images, or other materials users choose to display on them. Please
              keep in mind that any material you post is made available to the
              entire public and is not subject to this privacy policy.
            </p>
          </div>

          <h4 className="font-semibold text-2xl">THIRD-PARTY SERVICE LINKS</h4>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              The website may contain links to other pages that we believe may
              offer useful information. These linked pages are not under our
              control, and this privacy policy does not apply to them. We
              suggest that you contact them directly for information on their
              privacy, security, data collection, and distribution policies.
            </p>
          </div>

          <h4 className="font-semibold text-2xl">CONTACT INFORMATION</h4>
          <div className="py-[30px]">
            <p className="pb-[10px]">
              If you have any questions regarding this privacy policy or the
              personal data we collect, or if you wish to make any comments or
              complaints about anything related to this privacy policy, please
              contact us at the following email address:&nbsp;
              <a
                // target="_blank"
                href="mailto:support@usecrane.co"
                className="underline text-[blue]"
                // rel="noreferrer"
              >
                support@usecrane.co
              </a>
            </p>
          </div>

          <h4 className="font-semibold text-2xl">
            REVISIONS AND MODIFICATIONS
          </h4>
          <div className="py-[30px]">
            <p className="">
              We reserve the right to modify and revise this privacy policy from
              time to time without your explicit consent. If we make any
              fundamental changes, we will notify you and obtain your consent to
              the revised version.
            </p>
          </div>

          <p>Lagos, Nigeria</p>
          <p className="pt-[60px]">
            Feel free to reach out to us on &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
