import { useState } from "react";
import Accordion from "../../Accordion";
import {
  accountManagement,
  allFaqs,
  getStarted,
} from "../../../constants";
import { motion } from "framer-motion";
import { fadeIn } from "../../../utils/motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isFaqsOpen, setIsFaqsOpen] = useState(false);
  const [clickedFaqValue, setClickedFaqValue] = useState("All");

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // Mobile tab switch handler
  const handleMobileTabSwitch = (tab, index) => {
    setActiveTab(index);
    setClickedFaqValue(tab);
  };
  const toggleFaqsDropdown = () => {
    setIsFaqsOpen(!isFaqsOpen);
  };

  const tabs = [
    "All",
    "Getting started on Crane",
    " Account management / Transactions",
  ];

  return (
    <div>
      {/* Mobile Tab Selector */}
      <div className=" mx-auto flex flex-col lg:hidden w-11/12 justify-center items-center gap-5  mb-[40px]">
        <div className="mb-4  w-full relative " onClick={toggleFaqsDropdown}>
          <div className="flex justify-between items-center appearance-none border border-[#DCEBC9] hover:border-[#1AD05D] focus:border-[#1AD05D] focus:bg-[#DFF5C3] rounded-md w-full lg:p-4 p-2 text-[#050B05] h-[56px] font-medium leading-tight focus:outline-none focus:shadow-outline">
            {clickedFaqValue}
          </div>

          <div className="absolute right-[16px] bottom-[14px]  lg:right-[12px] gap-[20px] flex justify-center items-center cursor-pointer">
            {!isFaqsOpen ? (
              <div className=" w-[24px] h-[24px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014896/DropdownDown_qeelm6.svg"
                  alt="dropdown-icon"
                  title="dropdown-icon"
                  className="w-full h-full"
                />
              </div>
            ) : (
              <div className=" w-[24px] h-[24px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014929/DropdownUp_s2r1fc.svg"
                  alt="dropup-icon"
                  title="dropup-icon"
                  className="w-full h-full"
                />
              </div>
            )}
          </div>
          {isFaqsOpen && (
            <div className=" bg-[white] w-full absolute border right-0 z-50 drop-shadow-[#0000001F] rounded-[4px]">
              <ul className="divide-y  divide-light-blue-400 ">
                {tabs.map((tab, index) => (
                  <li
                    className=" cursor-pointer flex justify-between items-center  lg:text-xl text-sm font-semibold px-[20px] py-[16px] hover:bg-[#EAFEF5]"
                    key={index}
                    onClick={() => handleMobileTabSwitch(tab, index)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Desktop Tabs */}
      {/* */}
      <div className="hidden lg:flex m-auto z-10 justify-start items-center lg:w-[85%]  faqsScrollbar border-b border-[#EEEEEE]  overflow-x-scroll overflow-y-hidden ">
        {tabs.map((tab, index) => (
          <div key={index} className="relative lg:px-[10px] ">
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className="px-4 py-2  mx-2 h-[81px] text-[#050B05] whitespace-nowrap font-medium text-xl focus:outline-none "
            >
              {tab}
            </button>
            {/* Absolute div relative to the tab button */}
            {activeTab === index && (
              <div className="absolute  top-[90%] left-1/2 transform -translate-x-1/2 h-[8px] w-full bg-[#4EB855] rounded-lg"></div>
            )}
          </div>
        ))}
      </div>

      {/* Content */}
      <div className="mt-[24px] lg:mb-[46px] mb-[8px] lg:w-[85%] w-11/12 mx-auto flex justify-center items-center flex-col">
        {/* Background Image */}
        <div
          className="faq-bg-image bg-cover bg-center bg-no-repeat lg:h-[400px] h-[147px]  w-full rounded-tl-3xl rounded-tr-3xl"
          style={{
            backgroundImage:
              'url("https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706013785/AllIllustrations_qtxuny.svg")',
          }}
        ></div>

        {/* Tab Content */}
        <div className="bg-black pt-[24px] lg:pt-[91px] pb-[50px] lg:pb-[124px] w-full flex justify-center items-center ">
          {activeTab === 0 && (
            <motion.div
              variants={fadeIn("left", "tween", 0.2, 1)}
              className="w-full lg:w-1/2 "
            >
              {allFaqs?.map((item, index) => (
                <Accordion
                  key={index}
                  title={item.title}
                  content={item.content}
                  index={index}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              ))}
            </motion.div>
          )}
          {activeTab === 1 && (
            <motion.div
              variants={fadeIn("left", "tween", 0.2, 1)}
              className="w-full lg:w-1/2 "
            >
              {getStarted?.map((item, index) => (
                <Accordion
                  key={index}
                  title={item.title}
                  content={item.content}
                  index={index}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              ))}
            </motion.div>
          )}
          {activeTab === 2 && (
            <motion.div
              variants={fadeIn("left", "tween", 0.2, 1)}
              className="w-full lg:w-1/2 "
            >
              {accountManagement?.map((item, index) => (
                <Accordion
                  key={index}
                  title={item.title}
                  content={item.content}
                  index={index}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              ))}
            </motion.div>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default Tabs;
