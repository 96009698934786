
// import { motion } from "framer-motion";
// import {
//   staggerContainer,
// } from "../../utils/motion";
import ExchangeRate from "../exchangeRate/ExchangeRate";
import Feature from "../Feature";

const SecondSection = () => {
  return (
    <div
      // variants={staggerContainer}
      // initial="hidden"
      // whileInView="show"
      // viewport={{ once: false, amount: 0.25 }}
      className="bg-[#F2F4F8] pt-12 md:pt-24 pb-0 flex flex-col items-center justify-center text-center"
    >
      <Feature
        title="BEST MARKET RATES"
        subTitle="Best Market Rates on Lock Down"
        longText="Ditch the fluctuating rates—Crane guarantees the best market rates. Instantly check how much your crypto is worth in your local currency with our conversion calculator."
        maxWidth="606px"
        whiteSpace="normal"
      />

      <ExchangeRate />
    </div>
  );
};

export default SecondSection;
