import React, { useState } from "react";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import { Helmet } from "react-helmet-async";
import FirstSection from "../components/faqsComp/firstSection/FirstSection";
import Tabs from "../components/faqsComp/tabs/Tabs";

const Faqs = () => {
  const [isOpen, setIsOpen] = useState(false);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://usecrane.co/faqs" />

        <title>Product and all round crypto updates and news | Crane App</title>
        <meta
          name="title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
      </Helmet>
      
      <Navbar
        bgColor="[#F2F4F8]"
        textColor="[#050B05]"
        openModal={openModal}
      />
      <Modal onClose={closeModal} isOpen={isOpen} />
      <FirstSection/>
      <Tabs/>
      <Footer />
    </>
  );
};

export default Faqs;
