import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";

const Account = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [catchError, setCatchError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

   // Update button disabled state based on input values
   const updateButtonState = useCallback(() => {
    if (email === '' || reason.length <= 10) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [email, reason]);

  // Call updateButtonState whenever email or reason changes
  useEffect(() => {
    updateButtonState();
  }, [email, reason, updateButtonState]);

  const handleSubmit = () => {
    if (!isButtonDisabled) {
        // Reset successMessage and catchError state
    setSuccessMessage('');
    setCatchError('');
      setLoading(true);
      const data = {
        email,
       reason
      };
      axios
        .post("https://api.usecrane.co/v1/auth/account/delete", data)
        .then((response) => {
          // Handle the successful response
          setSuccessMessage(response?.data?.message)
          setLoading(false);
          setTimeout(() => {
            window.location.href = '/'; // Redirect to the home page after 3 seconds
          }, 3000);
        })
        .catch((error) => {
          // Handle any errors
          setCatchError(error?.response?.data?.message);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <Navbar bgColor="[#BFFBE2]" textColor="[#050B05]" openModal={openModal} />
      <Modal onClose={closeModal} isOpen={isOpen} />
      <div className=" flex items-center justify-center pt-[50px]">
        <div className="bg-white lg:pt-[30px] lg:px-[56px] lg:pb-[32px] pt-[15px] px-[18px] pb-[56px]">
          <div className="flex items-start justify-center flex-col">
            <h4 className="text-xl lg:text-[1.75rem] font-bold text-[#050B05]">
              Close my account and delete my data
            </h4>
            <p className="mt-[10px] lg:mt-[20px] mb-[28px] lg:mb-[20px] lg:text-xl font-medium text-sm max-w-[750px]">
              Enter your registered email address to delete your Crane account
              permanently. Account deletion cannot be undone. Closing your
              account means you:
            </p>
            <p className="font-medium">Lose all your data</p>
            <p className="font-medium">Lose all your app preferences</p>
            <p className="font-medium">Lose access to your app wallet</p>
            <p className="font-medium">Can’t view transaction activities</p>
            <p className="font-bold mt-[20px]">
              Click on the button below to delete your account permanently.
            </p>
          </div>

          {/* FORM FIELDS */}

          <div className=" mx-auto flex flex-col lg:flex-row justify-center items-center gap-5 lg:mt-[40px] mt-[30px]">
            <div className="w-full mb-4  ">
              <label
                htmlFor="nigerian-email"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email Address <span className="text-[red] font-bold">*</span>
              </label>
              <input
                type="email"
                id="nigerian-email"
                name="email"
                value={email}
                autoComplete="none"
                onChange={handleEmailChange}
                className=" appearance-none border hover:border-[#1AD05D] border-[#DCEBC9] focus:border-[#1AD05D] focus:bg-[#DFF5C3] rounded-md w-full lg:p-4 p-2 text-[#050B05] font-medium h-[56px] leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>

          <div className=" mx-auto flex flex-col lg:flex-row justify-center items-center gap-5 lg:my-[12px] my-[10px]">
            <div className="w-full mb-4  ">
              <label
                htmlFor="reason"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Reason <span className="text-[red] font-bold">*</span>
              </label>
              <input
                type="text"
                id="reason"
                name="reason"
                value={reason}
                autoComplete="none"
                onChange={handleReasonChange}
                className=" appearance-none border hover:border-[#1AD05D] border-[#DCEBC9] focus:border-[#1AD05D] focus:bg-[#DFF5C3] rounded-md w-full lg:p-4 p-2 text-[#050B05] font-medium h-[56px] leading-tight focus:outline-none focus:shadow-outline"
              />
              {catchError && (
                <p className="text-[red]">{catchError}</p>
              )}
              {successMessage && (
                <p className="text-[green]">
                 {successMessage}
                </p>
              )}
            </div>
          </div>

          <div className="flex lg:items-end lg:justify-end justify-center ">
            <motion.button
              whileHover={{
                scale: 1.1,
              }}
              className={` transition duration-300 ease-out text-sm lg:text-base
                 w-full h-[48px] lg:h-[64px] lg:w-[260px] text-white
                 font-semibold py-2 px-4  rounded lg:rounded-xl flex 
                 flex-row justify-center gap-4 items-center text-center
                 ${isButtonDisabled ? "bg-gray-300" : "bg-black"}`}
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              {loading ? "Deleting Account" : "Delete Account"}
            </motion.button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
