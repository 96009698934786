import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center text-center flex-col h-screen m-auto p-10">
      <h1 className="text-[#050B05] font-bold text-xl lg:text-[2.3rem] leading-normal">
        404 - Page Not Found
      </h1>
      <h3 className="text-[#050B05] font-medium text-sm lg:text-xl  py-2 leading-relaxed">
        Oops! Looks like you're exploring uncharted territory.
      </h3>
      <p className="text-[#050B05] font-medium text-sm lg:text-xl  py-2 leading-relaxed">
        The page you're looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>

      <Link to="/">
        <div className="flex lg:items-start items-center lg:justify-start justify-center pt-10">
          <motion.button
            whileHover={{
              scale: 1.1,
            }}
            className="bg-[#1AD05D] transition duration-300 ease-out h-[48px] lg:h-[64px] text-sm lg:text-base w-[248px] lg:w-[260px] text-[#050B05] font-semibold py-2 px-4 rounded-xl flex flex-row justify-center gap-4 items-center text-center"
          >
            Go to our Homepage
          </motion.button>
        </div>
      </Link>
    </div>
  );
};

export default NotFound;
