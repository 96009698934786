import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import FirstSection from "../components/instantConversionComp/FirstSection";
import SecondSection from "../components/instantConversionComp/SecondSection";
import ThirdSection from "../components/instantConversionComp/ThirdSection";
import Footer from "../components/footer/Footer";

const InstantConversion = () => {
  const [isOpen, setIsOpen] = useState(false);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
      <title> Convert Crypto to Cash</title>
      <meta
          name="title"
          content=" 
          Convert Crypto to Cash"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content=" 
          Convert Crypto to Cash"
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://usecrane.co/instant-conversion" />
        <meta
          name="description"
          content=" 
          Seamlessly convert crypto for instant fiat deposits into your bank account. Explore our secure platform for efficient transactions. Start converting now!"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content=" 
          Seamlessly convert crypto for instant fiat deposits into your bank account. Explore our secure platform for efficient transactions. Start converting now!"
          data-react-helmet="true"
        />
         <meta
          name="keywords"
          content="Convert crypto"
          data-react-helmet="true"
        />
     
      </Helmet>
      <Navbar
        bgColor="[#FFF]"
        textColor="[#050B05]"
        openModal={openModal}
  
      />
      <Modal onClose={closeModal} isOpen={isOpen} />

      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <Footer />
    </>
  );
};

export default InstantConversion;
