import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { bankDirect } from "../../constants";

const FifthSection = () => {
  return (
    <div className="bg-black w-full 2xl:pl-[140px] pt-[72px] 2xl:pt-[160px] 2xl:pb-[100px] ">
      <h2 className="text-white font-extrabold leading-tight px-[16px] md:text-[2rem] xl:text-[3rem] 2xl:text-[5rem]  w-11/12 text-[1.75rem] lg:w-[882px] 2xl:w-[1008px] text-left">
        Setting up&nbsp;
        <span className="inline-block text-[#1AD05D]">Bank Direct</span> is as
        easy as 1-2-3…or actually,
        <span className="inline-block text-[#C388F7]">
          &nbsp;4 simple steps!
        </span>
      </h2>

      <div className="flex flex-col 2xl:flex-row justify-between align-center">
        <div className="2xl:w-1/2 pt-[64px] w-full 2xl:pt-[160px] px-[16px] ">
          {bankDirect?.map((item, index) => (
            <div
              key={index}
              className={`flex justify-start items-start gap-[11px] 2xl:gap-[39px] ${
                index === bankDirect.length - 1
                  ? "mb-0"
                  : "mb-[44px] 2xl:mb-[96px]"
              }`}
            >
              {/* Image */}
              <div className="flex-shrink-0 xl:w-[52px] xl:h-[70px] w-[24px] h-[32px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/v1728478403/Crane_Chevron_2x_aene2t.png"
                  alt="An illustration of a user’s journey to enable Bank Direct: download the Crane app, turn on the feature, complete KYC tier 2, and select a bank account."
                  title="An illustration of a user’s journey to enable Bank Direct: download the Crane app, turn on the feature, complete KYC tier 2, and select a bank account."
                  className="w-full h-full object-contain"
                />
              </div>
              {/* Text div */}
              <p className="text-white text-[0.875rem] xl:text-[1.5rem] font-semibold xl:font-medium">
                <span className="text-[#1AD05D]">{item.title}</span>&nbsp;
                {item.subTitle}
              </p>
            </div>
          ))}
        </div>

        {/* Image div */}
        {/* <div className="flex-1 w-1/2 mx-auto">
        <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/v1728478405/Illustrations-Bank_Direct_Steps_2x_kt2aov.png"
            alt="An illustration of a user’s journey to enable Bank Direct: download the Crane app, turn on the feature, complete KYC tier 2, and select a bank account."
            title="An illustration of a user’s journey to enable Bank Direct: download the Crane app, turn on the feature, complete KYC tier 2, and select a bank account."
            className="w-full h-full"
          />
        </div> */}

        <div className="flex-1 mx-auto">
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/v1728478405/Illustrations-Bank_Direct_Steps_2x_kt2aov.png"
            alt="An illustration of a user’s journey to enable Bank Direct: download the Crane app, turn on the feature, complete KYC tier 2, and select a bank account."
            title="An illustration of a user’s journey to enable Bank Direct: download the Crane app, turn on the feature, complete KYC tier 2, and select a bank account."
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default FifthSection;
