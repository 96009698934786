// import LottieAnimation from "../../LottieAnimation";
import LottieAnimation from "../LottieAnimation";
import QrCodeAndDownloadButtons from "../qrCodeAndDownloadButtons/QrCodeAndDownloadButtons";
import blackSphere from "../../assets/lottie/Black Sphere.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { useEffect, useState } from "react";

const FirstSection = () => {
  // const [trigger, setTrigger] = useState(false);

  // useEffect(() => {
  //   // Example: Trigger animation when user scrolls to a certain position
  //   const handleScroll = () => {
  //     if (window.scrollY > 500) {
  //       setTrigger(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const className =
    "flex lg:items-start lg:justify-start items-center justify-center gap-[24px] xl:gap-[15px]";
  return (
    <div className="pb-[102px] md:pb-[140px]">
      <div className="bg-[#FFF] justify-between items-center lg:flex ">
        <div className=" flex justify-center 2xl:justify-start lg:w-1/2 mx-auto lg:ml-[5%] xl:ml-[5%]">
          <div className="flex-col px-8 self-center justify-around gap-10 lg:pb-[130px] pb-[62px]">
            <div className=" flex flex-row justify-center items-center gap-2 pt-[3.5rem] lg:pt-[7rem] lg:justify-start lg:text-left">
              <div className=" lg:w-[16px] lg:h-[16px] w-[12px] h-[12px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014489/Check_oyamc9.svg"
                  alt="check-icon"
                  title="check-icon"
                  className="w-full h-full"
                />
              </div>

              <div className="flex flex-col text-left justify-start text-[#525155] text-xs lg:text-base">
                Instant Conversion
              </div>
            </div>

            <h1 className="text-[#050B05] max-w-[670px] capitalize font-extrabold text-[1.75rem] lg:text-[3rem] xl:text-[4rem] leading-tight pt-6 text-center lg:text-left pb-[24px] xl:pb-[40px]">
              Skip the middleman,{" "}
              <span className="inline-block text-[#1AD05D] italic">
                Convert
              </span>{" "}
              your crypto earnings to local currency
            </h1>

            <div className="text-[#050B05] max-w-[722px] font-medium text-sm lg:text-xl pb-6 lg:pt-10 pb-[40px] lg:pb-[64px] text-center lg:text-left leading-relaxed">
              Making money is already difficult, receiving money doesn’t have to
              be difficult either. Crane makes cashing out your crypto a breeze
              - it's as effortless as sending a text.
            </div>

            <QrCodeAndDownloadButtons className={className} />
          </div>
        </div>

        <div className="lg:h-[657px] w-full lg:w-[700px] xl:w-[1200px] h-full relative">
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708611388/Instant_Convert_Illustration_2x_h5x0tx.jpg"
            alt="A stylized Bitcoin coin hovering above a green sphere with clouds and a shining star in the background."
            title="A stylized Bitcoin coin hovering above a green sphere with clouds and a shining star in the background."
            className=" w-full h-full "
          />

          <div className="blacksphere w-[179px] h-[128px] absolute top-[-60px] xl:top-[-100px] right-[50px] xl:w-[399px] xl:h-[268px] md:right-0 ">
            <LottieAnimation animationData={blackSphere}/>
          </div>
        </div>
      </div>

      <div className="px-8 mt-[80px] xl:ml-[5%] xl:ml-[5%] lg:w-[85%]">
        <h3 className="justify-start flex leading-tight  md:text-[3rem] sm:w-[350px] text-[1.75rem] md:w-[606px] font-medium text-[#050B05] text-left">
          Convert crypto to cash in few steps
        </h3>

        <div className=" flex flex-col 2xl:flex-row justify-start items-start mt-[39px] md:mt-[64px] gap-[12px] md:gap-[40px] xl:gap-[98px]">
          {/*  1*/}
          <div className=" flex flex-row 2xl:flex-col justify-start items-start gap-[12px] md:gap-[32px] 2xl:w-[312px]">
            {/*both images */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[36px] h-[36px] md:w-[64px] md:h-[64px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708682874/1_2x_nmxvpc.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
              {/* line image */}
              <div className=" md:w-[260px] flex-none hidden 2xl:block">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708685370/Line_254_2x_lhzq17.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
              {/* mobile line image */}
              <div className="h-[99px] flex-none block 2xl:hidden">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708685589/Line_254_2x_vwqfzm.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
            </div>

            <div className=" flex flex-col justify-start items-start gap-[10px] md:gap-[37px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Download the Crane app
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                Download and install the Crane App from your App store or Google
                Play Store
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className=" flex flex-row 2xl:flex-col justify-start items-start gap-[12px] md:gap-[32px] 2xl:w-[312px]">
            {/*both images */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[36px] h-[36px] md:w-[64px] md:h-[64px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708682886/2_2x_bba5iq.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
              {/* line image */}
              <div className=" md:w-[260px] flex-none hidden 2xl:block">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708685370/Line_254_2x_lhzq17.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
              {/* mobile line image */}
              <div className="h-[99px] flex-none block 2xl:hidden">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708685589/Line_254_2x_vwqfzm.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start gap-[10px] md:gap-[37px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Create and verify your account
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                Fill out your account information and complete your KYC
                verification.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className=" flex flex-row 2xl:flex-col justify-start items-start gap-[12px] md:gap-[32px] 2xl:w-[312px]">
            {/*both images */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[36px] h-[36px] md:w-[64px] md:h-[64px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708682886/3_2x_iktktl.png"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
              {/* line image */}
              <div className=" md:w-[260px] flex-none hidden 2xl:block">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708685370/Line_254_2x_lhzq17.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
              {/* mobile line image */}
              <div className="h-[99px]  flex-none block 2xl:hidden">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708685589/Line_254_2x_vwqfzm.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start gap-[10px] md:gap-[37px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Receive
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                Click on the receive button to view your wallet address
              </p>
            </div>
          </div>
          {/* 4 */}

          <div className=" flex flex-row 2xl:flex-col justify-start items-start gap-[12px] md:gap-[32px] 2xl:w-[312px]">
            {/*both images */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[36px] h-[36px] md:w-[64px] md:h-[64px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708682919/4_2x_qtbycy.jpg"
                  alt="instant conversion"
                  title="instant conversion"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start gap-[10px] md:gap-[37px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
                Convert
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
                Instantly CONVERT crypto to fiat.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
