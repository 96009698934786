import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { submenulinks } from "../../constants";
import LottieAnimation from "../LottieAnimation";
import Shimmers from "../../assets/lottie/Shimmers.json";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NavbarOptions = ({ textColor }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // Example: Trigger animation when user scrolls to a certain position
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setTrigger(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  
  const [openMenu, setOpenMenu] = useState(false);
  const submenuRef = useRef(null);

  useEffect(() => {
    // Function to close submenu when clicked outside
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    // Attach or remove event listener based on openMenu state
    if (openMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenu]);

  const openSubMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };

  return (
    <div className="relative">
      <div
        className="
    self-center flex-row md:gap-0 lg:gap-5 xl:gap-10 items-center hidden md:flex 
    "
      >
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={openSubMenu}
        >
          <p
            className={`py-2 px-4 font-semibold text-xl transition duration-300 hover:text-[#707070] text-${textColor}`}
          >
            Features
          </p>
          <div className="w-[32px] h-[32px] flex justify-center items-center ml-[-10px]">
            <LazyLoadImage
              src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014827/Dropdown_xhwq0g.svg"
              alt="dropdown icon"
              title="dropdown icon"
              className="w-full h-full"
            />
          </div>
        </div>

        <Link
          className={`py-2 px-4  font-semibold text-xl transition duration-300 cursor-pointer  hover:text-[#707070] text-${textColor}`}
          to="/faqs"
        >
          FAQs
        </Link>
        <a
          className={`py-2 px-4  font-semibold text-xl transition duration-300 cursor-pointer  hover:text-[#707070] text-${textColor}`}
          href="https://blog.usecrane.co/"
        >
          Blog
        </a>
        <Link
          className={`py-2 px-4  font-semibold text-xl transition duration-300 cursor-pointer  hover:text-[#707070] text-${textColor}`}
          to="/account"
        >
          Account
        </Link>
        {/* <a
          className={`py-2 px-4  font-semibold text-xl transition duration-300 cursor-pointer  hover:text-[#707070] text-${textColor}`}
          href="mailto:support@usecrane.co"
        >
          Contact Us
        </a> */}
      </div>

      {openMenu && (
        <div
          className="absolute h-[440px] w-[500px] lg:w-[600px] flex flex-col justify-between bg-[#FFFFFF] top-full mt-[51px] left-0 border rounded-[20px] pt-[20px] px-[30px] pb-[40px] submenu"
          ref={submenuRef}
        >
          {submenulinks?.map((menu) => (
            <Link to={menu.url}>
              <div
                className="flex gap-[20px] justify-start items-start submenulink"
                key={menu.id}
              >
                <div className="w-[64px] h-[64px] flex-none relative">
                  <LazyLoadImage
                    src={menu.image}
                    alt={menu.title}
                    title={menu.title}
                    className=" w-full h-full "
                  />
                  <div className=" absolute bottom-0 left-1/2 transform -translate-x-1/2 shimmer hidden">
                    <LottieAnimation
                      animationData={Shimmers}
                      style={{ width: 100, height: 50 }}
                      trigger={trigger}
                    />
                  </div>
                </div>
                <div className=" flex flex-col gap-[5px] items-start justify-start">
                  <h4 className="font-bold text-base text-[#050B05] ">
                    {menu.title}
                  </h4>
                  <p className="font-medium text-base text-[#707070] ">
                    {menu.text}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavbarOptions;
