import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Modal = ({ isOpen, onClose }) => {
  const modalStyles = isOpen ? "block" : "hidden";
  const [email, setEmail] = useState("");
  // const [country, setCountry] = useState("");
  // Define a state variable to store the checkbox value
  const [isChecked, setIsChecked] = useState(false);
  const [waitlistResponse, setWaitlistResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenCountries, setIsOpenCountries] = useState(false);
  const [clickedCountryValue, setClickedCountryValue] = useState("");

  // Add an event listener to handle the scroll behavior
  useEffect(() => {
    const handleScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add the event listener when the modal is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.addEventListener("scroll", handleScroll);
    } else {
      // Remove the event listener and reset styles when the modal is closed
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
      document.body.removeEventListener("scroll", handleScroll);
    }

    return () => {
      // Cleanup the event listener when the component unmounts
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  function capitalizeString(str) {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    } else {
      return ""; // Handle the case where the string is empty or undefined
    }
  }

  const capitalizedString = capitalizeString(clickedCountryValue);

  const handleCountryClick = (value) => {
    // Update the state with the clicked value
    setClickedCountryValue(value);
    setIsOpenCountries(false);
  };

  const toggleCountriesDropdown = () => {
    setIsOpenCountries(!isOpenCountries);
  };

  // Handler function to toggle the checkbox value
  const handleCheckboxChange = () => {
    setIsChecked((prevValue) => !prevValue);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const isButtonDisabled = email === "" || clickedCountryValue === "";

  const handleSubmit = () => {
    if (!isButtonDisabled) {
      setLoading(true);
      const data = {
        email: email,
        country: clickedCountryValue,
        device_type: "web",
      };

      axios
        .post("https://api.usecrane.co/v1/auth/waiting/list", data)
        .then((response) => {
          // Handle the successful response
          const status = response.data.status;
          if (status === true) {
            setWaitlistResponse(true); // Success case
          } else {
            setWaitlistResponse(false); // Failure case
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle any errors
          setWaitlistResponse(false);
          setLoading(false);
        });
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-[120] ${modalStyles} bg-[#00000038]`}
    >
      <div
        className="bg-white relative lg:pt-[60px] lg:px-[56px] lg:pb-[32px] pt-[48px] px-[18px] pb-[56px] rounded-none lg:rounded-xl shadow-md modal"
        style={{
          maxHeight: "90vh", // Set a maximum height for scrolling
          overflowY: "auto", // Add vertical scrollbar if content exceeds max height
        }}
      >
        <button
          className="flex justify-end items-end  lg:w-[40px] lg:mb-[60px] mb-[48px] lg:h-[40px] h-[30px] w-[30px]"
          onClick={onClose}
          style={{
            marginLeft: "auto",
          }}
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014540/CloseAffordance_osqdsx.svg"
            alt="close-icon"
            title="close-icon"
            className=" w-full h-full "
          />
        </button>

        {waitlistResponse ? (
          <>
            <div className="lg:w-[104px] lg:h-[104px] w-[60px] h-[60px] lg:mb-[48px] mb-[28px]">
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016160/Icons-Subscribed_amz9ht.svg"
                alt="waitlist-icon"
                title="waitlist-icon"
                className=" w-full h-full "
              />
            </div>
            <div className="">
              <h4 className="text-xl lg:text-[1.75rem] font-bold text-[#050B05]">
                You're on the list
              </h4>
              <p className="mt-[10px] lg:mt-[20px] mb-[100px] lg:mb-[160px] lg:text-xl font-medium text-sm max-w-[523px]">
                Subscribed! You'll be among the first to know when Crane
                launches.
              </p>
            </div>
            <div className="flex lg:items-end lg:justify-end justify-center ">
              <motion.button
                whileHover={{
                  scale: 1.1,
                }}
                className=" bg-black transition duration-300 ease-out text-sm lg:text-base w-full h-[48px] lg:h-[64px] lg:w-[260px] text-white font-semibold py-2 px-4  rounded lg:rounded-xl flex flex-row justify-center gap-4 items-center text-center"
                onClick={onClose}
              >
                Done
              </motion.button>
            </div>
          </>
        ) : (
          <>
            <div className="lg:w-[104px] lg:h-[104px] w-[60px] h-[60px] lg:mb-[48px] mb-[28px]">
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016212/Icons-Waitlist_rxatg2.svg"
                alt="waitlist-icon"
                title="waitlist-icon"
                className=" w-full h-full "
              />
            </div>

            <div className="">
              <h2 className="text-xl lg:text-[1.75rem] font-bold text-[#050B05]">
                Join our Waitlist
              </h2>
              <p className="mt-[10px] lg:mt-[20px] mb-[28px] lg:mb-[60px] lg:text-xl font-medium text-sm max-w-[750px]">
                {/* Share your details with us and we'll reach you as soon as Crane
                launches. You'll be the first to know. */}
                Share your information with us, and we'll get in touch with you
                as soon as Crane launches. You'll be among the first to receive
                updates 😊
              </p>
            </div>

            {/* FORM FIELDS */}
            <div className=" mx-auto flex flex-col lg:flex-row justify-center items-center gap-5 lg:mb-[48px]  mb-[40px]">
              <div
                className="mb-4  w-full relative "
                onClick={toggleCountriesDropdown}
              >
                <p
                  // htmlFor="country"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Country <span className="text-[red] font-bold">*</span>
                </p>
                <div className="flex justify-between items-center appearance-none border border-[#DCEBC9] hover:border-[#1AD05D] focus:border-[#1AD05D] focus:bg-[#DFF5C3] rounded-md w-full lg:p-4 p-2 text-[#050B05] h-[56px] font-medium leading-tight focus:outline-none focus:shadow-outline">
                  {/* type="country"
                  id="country"
                  name="country"
                  value={clickedCountryValue}
                  onChange={handleCountryClick}
                  disabled */}
                  {capitalizedString && capitalizedString}
                </div>

                <div className="absolute right-[16px] bottom-[14px]  lg:right-[12px] gap-[20px] flex justify-center items-center cursor-pointer">
                  {!isOpenCountries ? (
                    <div className=" w-[24px] h-[24px]">
                      <LazyLoadImage
                        src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014896/DropdownDown_qeelm6.svg"
                        alt="dropdown-icon"
                        title="dropdown-icon"
                        className="w-full h-full"
                      />
                    </div>
                  ) : (
                    <div className=" w-[24px] h-[24px]">
                      <LazyLoadImage
                        src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014929/DropdownUp_s2r1fc.svg"
                        alt="dropup-icon"
                        title="dropup-icon"
                        className="w-full h-full"
                      />
                    </div>
                  )}
                </div>

                {isOpenCountries && (
                  <div className=" bg-[white] w-full absolute bottom-[-100px] border right-0 z-50 drop-shadow-[#0000001F] rounded-[4px]">
                    <ul className="divide-y  divide-light-blue-400 ">
                      {/* <li
                    className=" cursor-pointer flex justify-between items-center lg:text-xl text-sm font-semibold px-[20px] py-[16px] hover:bg-[#EAFEF5]"
                    onClick={() => handleCountryClick("Nigeria")}
                  >
                    <div className="flex gap-[16px]">
                      <div className="w-[24px] h-[16px]">
                        <img
                          src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016011/Icons-Nigeria_2x_l9sopv.png"
                          alt="nigeria-icon"
                            title="nigeria-icon"
                          className=" w-full h-full "
                          // loading="lazy"
                        />
                      </div>
                      <p className="text-xs font-semibold"> Nigeria</p>
                    </div>

                    <p className="text-[#419822] text-xs">+234</p>
                  </li> */}
                      <li
                        className=" cursor-pointer flex justify-between items-center  lg:text-xl text-sm font-semibold px-[20px] py-[16px] hover:bg-[#EAFEF5]   "
                        onClick={() => handleCountryClick("kenya")}
                      >
                        <div className="flex gap-[16px]">
                          <div className="w-[24px] h-[16px]">
                            <LazyLoadImage
                              src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706015984/Icons-Kenya_2x_vj6lpo.png"
                              alt="kenya-icon"
                              title="kenya-icon"
                              className=" w-full h-full "
                            />
                          </div>
                          <p className="text-xs font-semibold"> Kenya</p>
                        </div>

                        <p className="text-[#419822] text-xs">+254</p>
                      </li>
                      <li
                        className=" cursor-pointer flex justify-between items-center  lg:text-xl text-sm font-semibold  px-[20px] py-[16px] hover:bg-[#EAFEF5]  "
                        onClick={() => handleCountryClick("ghana")}
                      >
                        <div className="flex gap-[16px]">
                          <div className="w-[24px] h-[16px]">
                            <LazyLoadImage
                              src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706015949/Icons-Ghana_2x_jnf5xy.png"
                              alt="ghana-icon"
                              title="ghana-icon"
                              className=" w-full h-full "
                            />
                          </div>
                          <p className="text-xs font-semibold"> Ghana</p>
                        </div>

                        <p className="text-[#419822] text-xs">+233</p>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="w-full mb-4  ">
                <label
                  htmlFor="foreign-email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email Address <span className="text-[red] font-bold">*</span>
                </label>
                <input
                  type="email"
                  id="foreign-email"
                  name="email"
                  value={email}
                  autoComplete="none"
                  onChange={handleEmailChange}
                  className=" appearance-none border hover:border-[#1AD05D] border-[#DCEBC9] focus:border-[#1AD05D] focus:bg-[#DFF5C3] rounded-md w-full lg:p-4 p-2 text-[#050B05] font-medium h-[56px] leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            {/* <p className="text-xs lg:text-base text-[#525155] lg:mt-[32px] lg:mb-[22px] mt-[24px] mb-[12px]">
              Asterisked <span className="text-[red] font-bold">*</span> items
              must be filled
            </p> */}

            {/* CHECKBOX */}
            <div className="lg:mt-[22px]  mt-[12px]">
              <label className="inline-flex lg:items-center items-start lg:mb-[120px] mb-[41px]">
                <input
                  type="checkbox"
                  className="form-checkbox custom-checkbox h-5 w-5 text-green-600"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  id="checkbox2"
                  autoComplete="none"
                />
                <span className="text-xs lg:text-base text-[#525155] ml-2">
                  {/* By selecting this option, you agree to  join Crane's community and gain exclusive access to exciting promotions
              & special offers. */}
                  By joining the waiting list, you agree to join Crane's
                  community and gain exclusive access to exciting promotions and
                  special offers.
                </span>
              </label>
            </div>

            <div className="flex lg:items-end lg:justify-end justify-center ">
              <motion.button
                whileHover={{
                  scale: 1.1,
                }}
                className={` transition duration-300 ease-out text-sm lg:text-base
                 w-full h-[48px] lg:h-[64px] lg:w-[260px] text-white
                 font-semibold py-2 px-4  rounded lg:rounded-xl flex 
                 flex-row justify-center gap-4 items-center text-center
                 ${isButtonDisabled ? "bg-gray-300" : "bg-black"}`}
                onClick={handleSubmit}
                disabled={isButtonDisabled}
              >
                {loading ? "Joining Waitlist..." : "Join Waitlist"}
              </motion.button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
