import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SeventhSection = () => {
  return (
    <div className=" lg:w-[85%] xl:w-11/12 bankDirect h-full xl:gap-[74px] mb-[63px] xl:mb-[140px] flex  justify-end  xl:flex-row flex-col border border-[#14F195] rounded-[32px] xl:rounded-[60px] w-11/12 mx-auto">
      <div className="flex flex-col flex-none xl:w-1/2 justify-start items-start lg:pl-[122px] xl:pl-[50px] px-[32px] lg:mb-[72px] mb-[44px] ">
        {/* lg:mb-[67px] mb-[320px] */}
        <p className=" lg:text-[5rem] leading-tight md:text-[1.5rem] sm:w-[350px] text-[1.75rem] md:w-[700px] 2xl:w-[838px] font-bold xl:pb-[40px] lg:pt-[64px] pb-[5px] pt-[36px]">
          Fast Payment, <br />
          Straight To Your{" "}
          <span className="inline-block text-[#1AD05D] italic">Bank</span>{" "}
        </p>
        <p className="max-w-[700px] text-sm lg:text-base font-medium text-[#525155] leading-normal pb-[29px] lg:pb-[64px]  lg:pb-[150px] ">
          Whether you're a freelancer, a growing business, a crypto enthusiast,
          or just someone tired of dealing with complex crypto transactions,
          Bank Direct is here for you. Ready to simplify how you get paid?
        </p>
        {/* button divs */}
        <div className="flex lg:justify-center items-center flex-col lg:flex-row gap-[24px] w-full xl:gap-[15px]">
          {/* Sign up button*/}

          <a
            href="https://crane.onelink.me/QQEs/zz9a92a7"
            className="bg-[black] rounded-xl py-[22px] px-[74px] hidden lg:flex  font-bold md:text-base text-sm cursor-pointer items-center justify-center text-white"
          >
            <motion.button
              whileHover={{
                scale: 1.1,
              }}
            >
              Get started&nbsp;
              <span className="inline-block w-[7px] h-[12px] ml-[16px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/v1728628225/Path_9685_2x_ysiris.png"
                  alt="arrow icon"
                  title="arrow icon"
                  className="w-[100%] h-[100%]"
                />
              </span>
            </motion.button>
          </a>

          {/*play store buttons  */}
          <div className="flex gap-3 items-center">
            <motion.a
              href="https://play.google.com/store/apps/details?id=com.crane.bigbird"
              target="_blank"
              rel="noreferrer"
              aria-label="download the app on play store"
              className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
              whileHover={{
                scale: 1.1,
              }}
            >
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1713965943/Google_Play_2x_nllouk.png"
                alt="google-play-icon"
                title="google-play-icon"
                className="w-full h-full"
              />
            </motion.a>
            <motion.a
              href="https://apps.apple.com/us/app/crane-app/id6502242343"
              target="_blank"
              rel="noreferrer"
              aria-label="download the app on ios"
              className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
              whileHover={{
                scale: 1.1,
              }}
            >
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1713966016/App_Store_2x_ahdbep.png"
                alt="app-store-icon"
                title="app-store-icon"
                className="w-full h-full"
              />
            </motion.a>
          </div>
        </div>
      </div>

      <div className="flex-1 lg:w-[700px] 2xl:w-[600px] xl:h-[750px] 2xl:h-[810px] lg:h-[570px] h-[325px] mx-auto sm:w-[357px] md:w-[500px]">
        <LazyLoadImage
          src="https://res.cloudinary.com/dpqxraalv/image/upload/v1728395668/Bank_Direct_Mock_2x_lvtysq.png"
          alt="Screenshots of Bank Direct app interface. The first shows a tooltip explaining quick, secure deposits into your local bank as fiat. The second confirms the feature is enabled. The third shows a transaction timeline: BTC received, converted to Naira, with the deposit pending due to network issues."
          title="Screenshots of Bank Direct app interface. The first shows a tooltip explaining quick, secure deposits into your local bank as fiat. The second confirms the feature is enabled. The third shows a transaction timeline: BTC received, converted to Naira, with the deposit pending due to network issues."
          className="w-[100%] h-[100%]"
        />
      </div>
    </div>
  );
};

export default SeventhSection;
