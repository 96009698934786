import React, { useState } from "react";
import { allFaqs } from "../../constants";
import BankDirectAccordion from "../BankDirectAccordion";

const SixthSection = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  return (
    <div className=" pt-[80px] pb-[100px] lg:pt-[120px] lg:pb-[280px] ">
      <h1 className=" flex justify-center text-center align-center mx-auto w-[288px] lg:w-[958px] lg:text-[4rem] text-[1.75rem] font-bold mb-[87px] lg:mb-[96px]">
        Got questions? We’ve got answers
      </h1>

      {/* ACCORDION */}
      <div className=" w-11/12 mx-auto lg:w-1/2">
        {allFaqs?.map((item, index) => (
          <BankDirectAccordion
            key={index}
            title={item.title}
            content={item.content}
            index={index}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
           
          />
        ))}
      </div>
    </div>
  );
};

export default SixthSection;
