import { motion } from "framer-motion";
import { useEffect, useState } from "react";
// import ReactPlayer from "react-player/lazy";
import { staggerContainer } from "../../utils/motion";
import LottieAnimation from "../LottieAnimation";
import clock from "../../assets/lottie/AutosellLottie.json";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FifthSection = () => {

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // Example: Trigger animation when user scrolls to a certain position
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setTrigger(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // THIS IS TO GET RID OF THE HYDRATION ERROR
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <div className="bg-[#14F195]">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="mx-auto justify-center items-center pt-20 pb-0 lg:pt-32  flex flex-col lg:w-3/4"
      >
        <div className="relative">
          {/* lottie clock */}
          <div className="lg:w-[236px] lg:h-[219px] w-[130px] h-[121px] ">
            <LottieAnimation animationData={clock} trigger={trigger}/>
          </div>
          {/* overlaying image */}

          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706015122/FadingEffect_peeghf.svg"
            alt="fading-img"
            title="fading-img"
            className="lg:w-[236px] lg:h-[219px] w-[130px] h-[121px] absolute top-[0px]"
          />
        </div>

        <div className=" lg:text-[4rem] text-[1.75rem] lg:pt-4 pt-8 font-extrabold lg:font-bold text-center px-8 lg:max-w-xl leading-tight">
          We Remember So You Don't Have To
        </div>
        <div className="max-w-[38rem] leading-normal px-8 lg:px-9 pb-8 lg:pb-30 text-center lg:pt-10 pt-6 font-medium text-[0.875rem] lg:text-[1.25rem]">
        With Crane’s auto-convert feature, your crypto is automatically sold when it hits your desired price.
         Boost your profits without constantly monitoring the market. <br/>
         Whether you’re busy or not tracking rates, we keep our eye on the ball so you never miss the ideal conversion moment.
   
        </div>

        {/* VIDEO PLAYER */}
        {mounted && (
          <div className="w-11/12 h-[90%] xl:w-[40%] mx-auto overflow-hidden">
            <video
              className="react-player"
              src="/videos/how to auto sell crane LR.mp4#t=0.1"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
              title="Crane app showcasing the ease of receiving and converting crypto to local currency"
              poster="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706012996/video-thumbnail.png"
            >
              <track
                kind="subtitles"
                src="captions-en.vtt"
                srclang="en"
                label="English"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default FifthSection;
