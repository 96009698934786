
import React, { useEffect, useState } from "react";

const ImageShuffler = () => {
  const images = [
    "https://res.cloudinary.com/dpqxraalv/image/upload/v1728550116/Crypto_Enthusiast_Tile_2x_vcgx8i.png",
    "https://res.cloudinary.com/dpqxraalv/image/upload/v1728550116/Freelancer_Tile_2x_oix0yf.png",
    "https://res.cloudinary.com/dpqxraalv/image/upload/v1728550119/Business_Tile_2x_bhiijr.png",
  ];

  const [imageOrder, setImageOrder] = useState([0, 1, 2]); // Indices of images in the order

  // Shuffle logic every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setImageOrder((prevOrder) => {
        // Move the first image to the end of the array
        const newOrder = [...prevOrder];
        const firstImage = newOrder.shift();
        newOrder.push(firstImage);
        return newOrder;
      });
    }, 3000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="relative mx-auto">
      {imageOrder.map((imageIndex, idx) => {
    
        const widthClass =
          idx === 0
            ? "w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%]"
            : idx === 1
            ? "w-[90%] sm:w-[90%] md:w-[90%] lg:w-[90%]"
            : "w-[80%] sm:w-[80%] md:w-[80%] lg:w-[80%]";

        const heightClass =
          idx === 0
            ? "h-[264px]  md:h-[600px] lg:h-[650px]"
            : idx === 1
            ? "h-[220px]  md:h-[550px] lg:h-[600px]"
            : "h-[204px]  md:h-[500px] lg:h-[550px]";

        return (
          <div
            key={imageIndex}
            className={`absolute transition-all duration-500 ease-in-out ${
              idx === 0 ? "z-30" : idx === 1 ? "z-20" : "z-10"
            } transform ${
              idx === 0
                ? "translate-y-0"
                : idx === 1
                ? "-translate-y-[20px]"
                : "-translate-y-[40px]"
            } ${widthClass} ${heightClass} left-1/2`}
            style={{
              transform: `translateX(-50%) translateY(${
                idx === 0 ? "0" : idx === 1 ? "-20px" : "-40px"
              })`,
              opacity: idx === 0 ? 1 : 0.9,
            }}
          >
            <img
              src={images[imageIndex]}
              alt=""
              className="w-full h-full" 
            />
          </div>
        );
      })}
    </div>
  );
};

export default ImageShuffler;
