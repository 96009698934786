

import { LazyLoadImage } from "react-lazy-load-image-component";

const Cards = ({ imgUrl, title, subTitle, iconUrl }) => {
  return (
    <div
      className="flex flex-1 flex-col max-w-[472px]  rounded-t-[80px] "
    >
      <div
        className="faq-bg-image bg-cover bg-center bg-no-repeat xl:h-[270px] h-[160px]  w-full lg:rounded-t-[80px] rounded-t-[20px]"
        style={{ backgroundImage: `url(${imgUrl})` }}
      ></div>

      <div className="flex flex-col bg-[#F2F4F8] lg:h-[800px] max-h-[850px]  h-[400px]">
        <div className="w-[60px] h-[60px] lg:w-[102px] lg:h-[102px] lg:mb-[84px] mb-[36px] lg:mt-[40px] mt-[16px] lg:mx-[40px] mx-[16px]">
          <LazyLoadImage
            src={iconUrl}
            alt="trade-icon"
            className="w-full h-full"
            title="trade-icon"
          />
        </div>
        <div className="md:text-4xl text-xl font-semibold text-left lg:mx-[40px] mx-[16px]">
          {title}
        </div>

        <div className="text-left pt-5 md:pt-8 xl:pb-8 text-xs md:text-xl lg:mx-[40px] mx-[16px] font-medium ">
          {subTitle}
        </div>
      </div>
    </div>
  );
};

export default Cards;
