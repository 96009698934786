import { motion } from "framer-motion";
import { otherPages, socials } from "../../constants";
// import { footerVariants } from "../../utils/motion";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => (
  <footer className="bg-[#050B05]  w-full mx-auto pt-12 px-6 lg:px-20 pb-12 lg:w-[85%] xl:w-11/12 mt-[16px] lg:mt-[26px]">
    <div className="flex xl:flex-row flex-col justify-start items-start gap-12 lg:gap-32">
      <div className="flex flex-col justify-start items-start gap-10 lg:gap-9">
        <Logo />
        <div className="bg-[#1d1c20] text-white lg:px-6 lg:pb-4 lg:pt-6 px-5 py-6 rounded-lg flex flex-col gap-[30px] lg:gap-9 ">
          <div className=" flex items-start gap-3 ">
            <div className="w-[28px] h-[28px] lg:w-[44px] lg:h-[44px] object-contain cursor-pointer">
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706015023/ElementWhiteBoarder_y4v13u.svg"
                className="w-full h-full "
                alt="element-white-boarder"
                title="element-white-boarder"
              />
            </div>

            <div className="flex gap-2 flex-col">
              <p className="text-xs lg:text-sm font-bold text-white">
                Your reliable sidekick for crypto off-ramp needs.
              </p>
              <p className="text-[0.625rem] lg:text-xs font-medium text-[#BDBDC0] ">
                Download the Crane App now to start!
              </p>
            </div>
          </div>

          <div className="flex gap-3 items-center">
            <motion.a
              href="https://play.google.com/store/apps/details?id=com.crane.bigbird"
              target="_blank"
              rel="noreferrer"
              aria-label="download the app on play store"
              className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
              whileHover={{
                scale: 1.1,
              }}
            >
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1713965943/Google_Play_2x_nllouk.png"
                alt="google-play-icon"
                title="google-play-icon"
                className="w-full h-full"
              />
            </motion.a>
            <motion.a
              href="https://apps.apple.com/us/app/crane-app/id6502242343"
              target="_blank"
              rel="noreferrer"
              aria-label="download the app on ios"
              className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
              whileHover={{
                scale: 1.1,
              }}
            >
              <LazyLoadImage
                src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1713966016/App_Store_2x_ahdbep.png"
                alt="app-store-icon"
                title="app-store-icon"
                className="w-full h-full"
              />
            </motion.a>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-7 lg:gap-9">
        <p className="text-white font-bold">SITEMAP</p>

        <div className="flex flex-col text-[#BDBDC0] font-medium lg:gap-4 gap-5 text-sm lg:text-base">
          {otherPages?.map((social) => (
            <Link
              to={social.url}
              key={social.id}
              className="cursor-pointer whitespace-nowrap"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
              }}
            >
              {social.page}
            </Link>
          ))}
        </div>
      </div>
      <div className=" flex flex-col gap-7 lg:gap-9">
        <p className="text-white font-bold">ABOUT US</p>
        <div className="text-[#BDBDC0] font-medium flex flex-col lg:gap-7 gap-5 lg:max-w-[400px] text-sm lg:text-base">
          <p className="leading-relaxed">
            Crane is a secure and reliable crypto remittance platform built on
            blockchain technology for receiving payments globally. We make it
            easy and convenient for Nigerians, Kenyans, and Ghanaians to convert
            their cryptocurrencies (Bitcoin, Ethereum, and more) into their
            local currency (NGN, KES, and GHS).
          </p>
          <p>
            Lagos, Nigeria.
            <br />
            <a href="mailto:support@usecrane.co">
              <span className="text-[#419822]"> support@usecrane.co</span>
            </a>
          </p>
        </div>
      </div>
    </div>

    {/* SOCIALS */}
    <div className="flex justify-between lg:items-center items-start lg:flex-row flex-col gap-[29px] mt-[32px] lg:mt-[81px] ">
      <p className="text-[#707070] lg:text-base text-[0.625rem] font-medium lg:order-1 order-2">
        © {new Date().getFullYear()} Crane by The Big Bird Tech Limited - All
        Rights Reserved.
      </p>

      <div className="flex gap-4 lg:order-2 order-1">
        {socials.map((social) => (
          <motion.a
            href={social.url}
            target="_blank"
            rel="noreferrer"
            className="w-[48px] h-[48px] lg:w-[60px] lg:h-[60px] object-contain cursor-pointer"
            key={social.id}
            aria-label={social.arialabel}
            whileHover={{
              scale: 1.1,
            }}
          >
            <LazyLoadImage
              key={social.name}
              src={social.imageUrl}
              alt={social.name}
              title={social.name}
              className="w-full h-full"
            />
          </motion.a>
        ))}
      </div>
    </div>
  </footer>
);

export default Footer;
