import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import logo from "../../assets/images/LogoDark_ljnya2.webp";

const Logo = () => {
  return (
    <Link to="/">
      <div className=" cursor-pointer md:w-[154px] md:h-[52px] h-[40px] w-[110px]">
        <LazyLoadImage
          src={logo}
          alt="Crane app Logo"
          title="Crane app Logo"
          className=" w-full h-full"
        />
      </div>
    </Link>
  );
};

export default Logo;
