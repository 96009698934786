import { motion } from "framer-motion";
import { staggerContainer } from "../../../utils/motion";

const FirstSection = () => {
  return (
    <div className="bg-[#F2F4F8]">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="m-auto justify-between items-center flex lg:w-[85%] lg:pt-10 lg:pb-24 mb-[40px] lg:mb-[30px]"
      >
        <div className="justify-center flex lg:w-1/2">
          <div className="flex-col px-8 self-center flex justify-around gap-10">
            <div className="text-[#050B05] font-extrabold text-[1.75rem] lg:text-[3rem] leading-tight text-center pt-7 lg:text-left">
              How may we help you today?
            </div>
            <div className="text-[#050B05] font-medium text-sm lg:text-xl pt-6 lg:pt-10 pb-10 lg:pb-16 text-center lg:text-left leading-relaxed">
              We answered some of the questions we think you may want to ask. If
              you can't find answers to your question here, you can&nbsp;
              <a
                href="mailto:support@usecrane.co"
                className="text-[#419822] underline"
              >
                reach out to us&nbsp;
              </a>
              for further assistance.
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default FirstSection;
