import { useState } from "react";
import { motion } from "framer-motion";
import { QRCodeCanvas } from "qrcode.react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const QrCodeAndDownloadButtons = ({ className }) => {
  const [hover, setHover] = useState(false);
  const handleMouseLeave = () => {
    setHover(false);
  };
  const handleMouseEnter = () => {
    setHover(true);
  };
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div className={className}>
      {/* <motion.a href="" target="_blank" rel="noreferrer"   whileHover={{
    scale: 1.1,
  }}>
                <button
                
                  className="bg-black transition duration-300 ease-out text-sm lg:text-base w-[212px] h-[48px] lg:h-[64px] lg:w-[260px] text-white font-semibold py-2 px-4 rounded-xl flex flex-row justify-center gap-4 items-center text-center"
                >
                  Get Started
                  <div className="w-[7px] h-[12px]">
                    <img  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016873/Path9685_b5akay.svg"
                    alt="get-started-button"
                        title="get-started-button"
                        // loading="lazy"
                    className=" w-full h-full "/>
                  </div>
                
                </button>
              </motion.a> */}
      {/*play store buttons  */}
      <div className="flex gap-3 items-center">
        <motion.a
          href="https://play.google.com/store/apps/details?id=com.crane.bigbird"
          target="_blank"
          rel="noreferrer"
          aria-label="download the app on play store"
          className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
          whileHover={{
            scale: 1.1,
          }}
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/v1706015436/Google_Play_2x_wjrqnd.png"
            alt="google-play-icon"
            title="google-play-icon"
            className="w-full h-full"
          />
        </motion.a>
        <motion.a
          href="https://apps.apple.com/us/app/crane-app/id6502242343"
          target="_blank"
          rel="noreferrer"
          aria-label="download the app on ios"
          className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
          whileHover={{
            scale: 1.1,
          }}
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/v1706013834/App_Store_2x_okviuw.png"
            alt="app-store-icon"
            title="app-store-icon"
            className="w-full h-full"
          />
        </motion.a>
      </div>

      {/* qr code */}
      <div
        className="justify-start items-center gap-[8px] hidden lg:flex cursor-pointer relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <div className="w-[48px] h-[48px] xl:w-[60px]">
                  <img
                    src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016051/Icons-QR_Code_2x_bytjpm.png"
                    alt="qr-code-mini"
                     title="qr-code-mini"
                    className=" w-full h-full "
                    // loading="lazy"
                  />
                </div> */}
        <p className="text-sm lg:text-base font-medium max-w-[192px] text-[#525155]">
          Show QR code to download the Crane app
        </p>
        {/* main qr code for scan */}
        {hover && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            className="absolute w-[150px] flex justify-center items-center h-[150px] bottom-[-155px] right-[20px] bg-[white]  bg-opacity-1 rounded-[10px] "
            style={{ zIndex: 100 }}
          >
            <QRCodeCanvas value="https://crane.onelink.me/QQEs/zz9a92a7" />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default QrCodeAndDownloadButtons;
