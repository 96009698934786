
async function getCountry() {
  try {
    const response = await fetch("https://api.usecrane.co/v1/public/user/ip");
    const data = await response.json();
    return data.data.country;
    
  } catch (error) {
    console.error("Error fetching country:", error);
    return null;
  }
}

export default getCountry;
