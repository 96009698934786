import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";

const Aml = () => {
  const [isOpen, setIsOpen] = useState(false);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://usecrane.co/aml" />
        <title>Product and all round crypto updates and news | Crane App</title>
        <meta
          name="title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
        
   
      </Helmet>
      <Navbar
        bgColor="[#BFFBE2]"
        textColor="[#050B05]"
        openModal={openModal}
      />
      <Modal onClose={closeModal} isOpen={isOpen} />
  

      {/*Content Body */}
      <div className="w-full mx-auto  lg:w-[85%] xl:w-11/12 py-[50px] px-[30px] xl:py-[80px] xl:px-[60px]">
        <h2 className="xl:text-5xl text-3xl font-semibold pb-[10px]">
          AML/KYC Policy
        </h2>
        <p className="font-medium xl:pb-[80px] pb-[60px]">
          Effective Date: November 11, 2023
        </p>
        <h4 className="font-semibold xl:text-2xl text-xl">DEFINITIONS</h4>
        <div className="xl:p-[30px] py-[20px] px-[10px]">
          <p className="pb-[5px]">
            a. <b>Money Laundering (ML):</b> This is the process by which
            criminals attempt to conceal the origin and/or illegitimate
            ownership of property and assets that are the proceeds of criminal
            activities.
          </p>
          <p className="pb-[5px]">
            b. <b>Terrorism Financing: </b>This includes both legitimate and
            illegitimate money characterised by concealment of the origin or
            intended criminal use of the funds.
          </p>
          <p className="pb-[5px]">
            c. <b>Know Your Customer (KYC):</b> This entails obtaining and
            verifying customer identity, preservation of customer records, and
            the mandatory disclosure of transactions to authorised statutory
            bodies.
          </p>
          <p className="pb-[5px]">
            d. <b>Nigeria Financial Intelligence Unit (NFIU):</b> The Nigerian
            arm of the global Financial Intelligence Unit (FIU).
          </p>
          <p className="pb-[5px]">
            e. <b>Politically Exposed Persons (PEPs):</b> Individuals who have
            been entrusted with prominent public functions in any country.
            Generally presenting a higher risk for potential involvement in
            bribery and corruption by virtue of their position and the influence
            that they may hold.
          </p>
          <p className="pb-[5px]">
            f. <b>Enterprise risk management (ERM):</b> This includes the
            methods and processes used by organisations to manage risks and
            seize opportunities related to achieving their objectives.
          </p>
          <p className="pb-[5px]">
            g. <b>Client: </b>This includes any entity with a business
            relationship with the Company or any entity connected with a
            financial transaction that can pose significant reputational or
            other risks to the Company
          </p>
        </div>

        <h4 className="font-semibold text-2xl">Policy mandate</h4>
        <div className="py-[30px]">
          <p className="pb-[15px]">
            This policy is linked to a more extensive internal anti-money
            laundering (AML), know your customer (KYC), and combating the
            financing of terrorism policy (CFT). It outlines a condensed version
            of the requirements for Crane by The Big Bird Tech Ltd. (herein
            referred to as "the Company") in compliance with AML/CFT
            requirements under the law and regulatory directives. Any
            transaction that supports illicit activity is stopped by it.
          </p>
          <p className="">
            Crane is aware of the crucial role it plays in ensuring that
            criminals don't use our services to assist in money laundering and
            the financing of terrorism. We are devoted to stopping and
            preventing money laundering, and when necessary, we will work
            closely with regulators and law enforcement.
          </p>
        </div>

        <h4 className="font-semibold text-2xl">Policy description</h4>
        <div className="py-[30px]">
          <p className="pb-[15px]">
            Money laundering and financing terrorism are financial crimes with
            economic effects. This policy is to eradicate the adverse effects of
            criminal economic activity and promote integrity and stability in
            financial markets. Compliance with this policy is also critical to
            preserving the company’s corporate integrity, reputation, and
            operational efficiency.
          </p>
          <p className="">The purpose of this policy is:</p>

          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            To guide the standard of conduct and practise that Crane must follow
            in implementing the AML, KYC, and CFT regulations.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            To protect the company against fraud, reputational, and other
            financial market risks.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            To minimise the risks faced by the Company from proceeds of crime
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            To prevent money laundering and establish ERM systems to monitor the
            company’s exposure to financial crime.
          </p>
        </div>

        <h4 className="font-semibold text-2xl">Compliance with policy</h4>
        <div className="py-[30px]">
          <p className="">The company shall comply with the following:</p>

          <div className="xl:p-[30px] py-[20px] px-[10px]">
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Formulate and implement internal controls and other procedures
              that will deter criminals from using its facilities for money
              laundering and terrorist financing and ensure that its obligations
              under Nigerian and subsisting laws and regulations are met.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Embark on Enterprise Risk Management (ERM) and maintain an ERM
              register.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Designate a Money Laundering Reporting Officer (MLRO) with the
              relevant competence and independence to implement the company’s
              AML/KYC compliance policy.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Comply with the Money Laundering (Prohibition) Act, the Money
              Laundering (Prohibition) (Amendment) Act (together, the Money
              Laundering Act), and the Economic and Financial Crimes Commission
              Act.
            </p>

            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Identify and report any suspicious transactions related to the
              criminal activities defined in the AML/KYC Regulations.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Ensure the implementation of the AML/KYC Act requirements is not
              inhibited through the company’s confidentiality agreement or
              policy.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Effectively communicate and raise staff awareness on AML/KYC
              issues
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Establishing and maintaining a risk-based approach to assessing
              and managing money laundering and terrorist financing risks
            </p>
          </div>
        </div>

        <h4 className="font-semibold text-2xl">Know Your Client</h4>
        <div className="py-[30px]">
          <p className="">
            KYC is the due diligence that the company must perform to identify
            their clients and ascertain relevant information before carrying out
            any financial business with them. The company, in upholding its KYC
            policies, shall undertake the following:
          </p>

          <div className="xl:p-[30px] py-[20px] px-[10px]">
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Establishing and maintaining a risk-based approach to Customer Due
              diligence (CDD), Enhanced Due Diligence (EDD), including customer
              identification, verification, and KYC procedures
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Issue a KYC compliance form to clients
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Obtain the necessary documents and information from every client
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Report suspicious activities and transactions to the regulatory
              authorities
            </p>

            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Update client information as and when it becomes available
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Identify the client and verify the client’s identity using
              reliable, independent source documents (e.g., passport data page,
              national ID card, voter card, utility bills stating their current
              postal address, e.t.c.), data, or information.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Verify the legal status of business names, incorporated trustees,
              and companies with the Corporate Affairs Commission.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Refuse to transact business with “shell companies” as described
              under the International Conventions.
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Conduct due diligence for higher-risk clients, business
              relationships, or transactions, including PEP, cross-border
              transactions, and business relationships.
            </p>
          </div>
        </div>

        <h4 className="font-semibold text-2xl">Record-keeping and reporting</h4>
        <div className="py-[30px]">
          <p className="">
            The company shall keep a record of a customer’s identification for
            at least six years after the closure of the account or the severance
            of relations with the customer. Upon request by a regulatory or law
            enforcement agency, the company shall make available records related
            to AML/CFT compliance or its clients as soon as possible from the
            date of the request. If the company notices any illegal transaction,
            it shall:
          </p>

          <div className="xl:p-[30px] py-[20px] px-[10px]">
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Draw up a report on the identity of the principal and the
              beneficiary or beneficiaries;
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Take appropriate action to prevent the laundering of the proceeds
              of criminal conduct;
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Send a copy of the report and action taken to the Nigerian
              Financial Intelligence Unit (NFIU);
            </p>
          </div>
        </div>

        <h4 className="font-semibold text-2xl">
          Politically Exposed Persons (PEPs)
        </h4>
        <div className="py-[30px]">
          <p className="">
            Business relationships with family members or close associates of
            PEPs involve reputational risks similar to those of the PEPs
            themselves. The company shall evaluate the risks to its business
            operations when dealing with PEPs.
          </p>
        </div>

        <h4 className="font-semibold text-2xl">Sanctions</h4>
        <div className="py-[30px]">
          <p className="">
            A breach of the AML/CFT is a severe offence and could lead to
            investigations, the imposition of fines, and criminal sanctions
            (including imprisonment).
          </p>
        </div>
        <p>Lagos, Nigeria</p>
        <p className="pt-[60px]">
          Feel free to reach out to us on &nbsp;
          <a
            // target="_blank"
            href="mailto:support@usecrane.co"
            className="underline text-[blue]"
            // rel="noreferrer"
          >
            support@usecrane.co
          </a>
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Aml;
