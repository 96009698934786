import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://usecrane.co/termsandconditions" />
        <title>Product and all round crypto updates and news | Crane App</title>
        <meta
          name="title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content="Product and all round crypto updates and news | Crane App"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Stay ahead in the crypto game with our comprehensive product offerings and up-to-the-minute updates. Explore a world of cryptocurrency news, ensuring you're always in the loop. Elevate your crypto experience with us"
          data-react-helmet="true"
        />
      </Helmet>
      <Navbar
        bgColor="[#BFFBE2]"
        textColor="[#050B05]"
        openModal={openModal}

      />
      <Modal onClose={closeModal} isOpen={isOpen} />
  

      {/*Content Body */}
      <div className="w-full mx-auto  lg:w-[85%] xl:w-11/12 py-[50px] px-[30px] xl:py-[80px] xl:px-[60px]">
        <h1 className="xl:text-5xl text-3xl font-semibold pb-[10px]">
          Terms and Conditions
        </h1>
        <p className="font-medium xl:pb-[80px] pb-[60px]">
          Effective Date: November 11, 2023
        </p>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">
            1. Acceptance of Terms
          </h4>
          <p className="pb-[10px]">
            The following terms and conditions (the "Agreement") are a binding
            agreement between you and Crane by The Big Bird Tech, a private
            limited liability company registered in the Federal Republic of
            Nigeria (hereinafter referred to as Crane/Us/We/The Company), and
            they are applicable to anyone who utilises the Crane application,
            any related websites, retail goods, and/or services (the "Crane App
            and Services").
          </p>
          <p className="pb-[10px]">
            You acknowledge that you have read, comprehended, and accepted all
            of the terms and conditions contained in this agreement, as well as
            our privacy policy, before using the Crane App and Services.
            Additionally, you acknowledge and accept that your use of the
            application may be subject to all applicable laws, rules,
            regulations, and requirements of the jurisdiction in which you
            reside
          </p>
          <p className="pb-[10px]">
            You must abide by the terms and conditions of this agreement in
            order to use the Crane app and services. Before creating an account,
            please read the terms carefully, as they have an impact on your
            legal rights and obligations. Please don't use the Crane app or
            services if you disagree with any of the terms in this agreement.
          </p>

          <p className="pb-[10px]">
            We are constantly improving the services, and periodically we will
            need to revise or amend the terms. Accordingly, we reserve the right
            to amend the Terms at any time for any reason without notice to you.
            The revised version will be effective at the time we publish it on
            our website or within the application, with the “Last Modified” date
            provided at the top of the Terms. Continuing to use the services
            will constitute your acceptance of these terms. You will be deemed
            to have accepted these terms as updated if you keep using the
            services.
            <b>
              If you do not agree to the revised terms, do not use or access the
              service.
            </b>
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">2. Eligibility</h4>
          <p className="pb-[10px]">
            To use our application and services, you must be at least 18 years
            old or have reached the applicable age of maturity as specified by
            applicable legislation. You must also have the full legal ability to
            accept this agreement and engage in any transactions on the app. If
            you learn that your child or ward has given us any information
            without your permission, please get in touch with us using the
            information on the Privacy Policy.
          </p>
          <p className="pb-[10px]">
            Also, the countries that Crane operates in and where you reside will
            determine whether you are eligible to use specific Crane app and
            service features.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">3. Risks</h4>
          <p className="pb-[10px]">
            You should make sure you understand the risks involved in
            purchasing, selling, or trading digital currencies or digital assets
            before using the Crane app or services. These markets are known to
            be unstable, and prices can change dramatically. As a result, the
            value of your assets may experience abrupt and significant increases
            or decreases. You recognise that "digital assets" refer to digital
            currencies, their derivatives, or other types of digitalized assets
            with a certain value, while "digital currencies" refer to encrypted
            or digital tokens or cryptocurrencies with a certain value that are
            based on blockchain and cryptography technologies and are issued and
            managed in a decentralised form, which may or may not be regulated
            in your jurisdiction.
          </p>
          <p className="pb-[10px]">
            If you plan to acquire, sell, or trade digital assets or digital
            currencies, you should carefully consider whether your financial
            situation and risk tolerance allow for it. You acknowledge and
            understand that you alone are responsible for any decision you make
            about the sale, exchange, or other handling of cryptocurrencies.
          </p>

          <p className="pb-[10px]">
            Under no circumstances does any information contained on the Crane
            App, provided to you through your Crane Account, or provided by any
            employee, agent, or affiliate of Crane constitute financial,
            investment, or other professional advice. You are solely responsible
            for any decision to store or sell cryptocurrency, and such decisions
            should take into account your risk tolerance and financial
            circumstances.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">4. About Crane</h4>
          <p className="pb-[10px]">
            Crane allows holders of a Crane Account to access the following
            basic services (“Crane Services”) via the application:
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Sell the supported digital assets in transactions with Crane
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>A wallet to
            receive and store digital currencies and local fiat currency (e.g.,
            Nigerian Naira) “Crane Wallet” for use in connection with the Crane
            Services.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Withdraw local fiat currency.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">5. Using the App</h4>
          <p className="pb-[10px]">
            You must open a Crane account and confirm your acceptance of this
            agreement in order to use the Crane app and services. If you are
            suspected of breaking this agreement or for any other reason deemed
            necessary, Crane may, in its sole discretion, revoke account
            registrations, suspend or limit access to your Crane Account and/or
            the Crane Services, and/or limit the number of Crane Accounts a
            single user may establish and maintain.
          </p>
          <p className="pb-[10px]">
            Your full name, phone number, e-mail address, picture, physical
            address, date of birth, government-issued identification, and
            information about your bank account (such as the financial
            institution and account number) may be requested in order to verify
            your identity, comply with any applicable regulatory requirements,
            and prevent money laundering, terrorist financing, fraud, and other
            financial crimes. In order to enhance account limits, comply with
            regulatory requirements, or in other situations where it may be
            judged appropriate, you could also need to produce more
            documentation. By providing this or any other personal information
            that may be needed, you attest to the accuracy and authenticity of
            the information provided, agree to update Crane if any information
            changes, and further give Crane your permission to make any
            inquiries we deem necessary to confirm your identity and/or protect
            against fraud or other crimes, either directly or through third
            parties. You also give permission for any and all third parties to
            whom the questions or requests may be addressed to fully reply to
            them. You agree to give Crane this information and to allow Crane to
            maintain a record of it for the duration of your account plus seven
            (7) years after account cancellation in order to comply with
            international industry standards for data retention.
          </p>

          <p className="pb-[10px]">
            Although we have taken all reasonable steps to ensure the accuracy
            of the information on our website, we cannot guarantee its accuracy,
            suitability, reliability, completeness, performance, and/or fitness
            for purpose, and we will not accept liability for any loss or damage
            that may result directly or indirectly from the content or from your
            inability to access the site, or for any delay in or failure to do
            so. The information and materials on the website are also subject to
            change without prior notice and are only offered to help users make
            their own decisions. We will not be liable in any way for the use or
            interpretation of such information.
          </p>
          <p className="pb-[10px]">
            When you use our services, you agree to our information services.
            You grant us permission to contact you with commercial information
            via email, SMS, mobile notification, or postal mail. By altering the
            necessary settings on the website and mobile app, you can choose not
            to use such services. You acknowledge and agree that we will provide
            the services in accordance with our technological capability and
            other current circumstances. Despite our best efforts to ensure the
            continuity and security of the services, we cannot completely
            anticipate or mitigate all legal, technological, and other risks,
            including but not limited to force majeure, viruses, hacker attacks,
            system instability, flaws in third-party services, acts of
            government, and other losses and risks.
          </p>
          <p className="pb-[10px]">
            We do not accept liability for damages incurred when the system
            platform is unable to function properly as a result of the following
            events, preventing the user from using the services or placing or
            cancelling an order. These scenarios include, but are not limited
            to:
          </p>

          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            System downtime during maintenance;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Telecom or Networking equipment issues;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Typhoon, earthquake, tsunami, flood, power failure, war, terrorist
            attacks, and other force majeure factors
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Any other issues, such as hacker intrusions or attacks, computer
            virus intrusions or attacks, website or backend maintenance and
            upgrade, banking-related problems, government regulations or
            mandates, or problems with any third parties;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Damages brought on by third parties to users or other third parties
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            We reserve the right to revoke the outcomes of anomalous
            transactions in the event of market disruption, abnormal
            transactions, or other abnormal circumstances brought on by system
            failure, network failure, distributed denial of service (DDoS)
            attacks, other hacker attacks, or unanticipated events. All of the
            transactions from a specific period of time may be rolled back at
            any time.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            We won't ask our users for a password or for any funds or addresses,
            as appropriate, that aren't specified on the trading platform. Any
            losses resulting from the transmission of money to addresses that
            aren't on the trading platform, as appropriate, are not our
            responsibility.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">6. Crane Wallet</h4>
          <p className="pb-[10px]">
            The Crane Wallet allows you to request, receive, and store supported
            digital assets from third parties based on instructions provided by
            the Crane App. Crane retains the right to set a maximum number of
            digital assets that can be sold.
          </p>
          <p className="pb-[10px]">
            You can immediately sell supported digital assets from Crane after
            successfully completing the verification processes. For all
            transactions, there is a processing fee.
          </p>

          <p className="pb-[10px]">
            In the event of a subpoena, court order, or other legally binding
            government order, Crane has the right to refuse to conduct the
            transaction or to cancel it. It also reserves the right to enforce
            transaction restrictions.
          </p>
          <p className="pb-[10px]">
            When a digital asset transaction is broadcast to the network of the
            digital asset, Crane cannot undo it.
          </p>
          <p className="pb-[10px]">
            The Crane Wallet is only used in conjunction with the fiat
            currencies and/or digital assets that Crane, in its sole discretion,
            chooses to support. Under no circumstances should you try to use the
            Crane Wallet services to store, transfer, request, or receive money
            or digital assets in any format that Crane does not support. In the
            event that a user attempts to use a currency that Crane does not
            support, Crane disclaims all duty and obligation.
          </p>
          <p className="pb-[10px]">
            The identity of any user, recipient, requester, or other party is
            not guaranteed by Crane, which executes all transactions in
            accordance with the instructions given by its users. You should
            double-check all transactional information before executing orders
            on Crane. When a digital asset transaction is sent to a network, it
            is initially unconfirmed until the network has sufficiently
            confirmed the transaction. While it is still pending, a transaction
            is not complete. Your Crane Account's available balance and the
            funds connected to transactions that are in pending status will be
            identified as such and won't be accessible for use in transactions.
            In order to complete a digital asset transaction on your behalf,
            Crane may collect network fees (miner fees). Crane will decide how
            to calculate the network cost, but you will always be informed of it
            at or before the moment you authorise the transaction.
          </p>
          <p className="pb-[10px]">
            Except for digital assets sold through the Crane App, Crane has no
            control over and has no responsibility for the delivery, quality,
            safety, legality, or any other aspect of any goods or services that
            you may buy or sell to or from third parties (including other users
            of Crane Services). The underlying software protocols that control
            how the digital currencies supported by our platform function are
            not owned or controlled by Crane. The underlying protocols are
            typically open source, meaning that anybody can use, copy, edit, and
            distribute them. By using the Crane platform, you accept and agree
            to the following:
          </p>

          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            The functioning, security, and availability of the underlying
            protocols are not under Crane's control or assurance
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] pb-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            The underlying protocols are subject to sudden changes in operating
            rules (a.k.a. “forks”), and such forks may materially affect the
            value, function, and/or even the name of the digital asset you store
            on the Crane platform. You acknowledge that in the event of a fork,
            Crane may temporarily halt operations (with or without prior
            notification to you) and that Crane may, in its sole discretion,
            choose whether or not to continue supporting either branch of the
            forked protocol. You acknowledge and accept that Crane takes no
            responsibility whatsoever for a forked protocol's unsupported
            branch.
          </p>
          <p className="pb-[10px]">
            Fiat money transferred into your Crane Wallet can only be used to
            withdraw money to an authorised bank account. We might also let you
            use your balance to buy particular items and/or services from chosen
            third parties. You agree that any such purchase will be subject to
            these terms. Less any relevant fees, the proceeds from the sale of
            supported digital assets will be credited to the Crane Wallet. Crane
            does not pay interest on any amounts maintained in your Crane Wallet
            in fiat currency or digital assets
          </p>
          <p className="pb-[10px]">
            Any transaction that is flagged as complete or pending cannot be
            changed, reversed, or cancelled. You give Crane permission to void
            the transaction at its sole discretion if your payment is declined
            or your payment method does not have enough money. To prevent
            overdrafts, insufficient funds, or other fees assessed by your
            payment provider, it is your responsibility to keep a sufficient
            balance and/or credit limitations.
          </p>
          <p className="pb-[10px]">
            When funds in your Crane wallet belong to Crane, another client
            besides you, or a third party, such as a partner or supplier, you
            understand and agree that Crane reserves the right to unilaterally
            deduct those funds. The following situations give Crane the right to
            use the aforementioned unilateral deduction clause:
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            To protect, preserve, or recover funds that Crane has reasonably
            determined were incorrectly, unintentionally, or wrongly deposited
            in your Crane wallet, whether as a result of computer or human
            error; or
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            To recover funds that Crane has reasonably determined were overpaid
            to your Crane wallet in relation to an already-completed
            transaction, whether due to computer or human error
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">
            7. General Use, Prohibited Use and Termination
          </h4>
          <p className="pb-[10px]">
            Under the terms of this Agreement, we grant you a limited,
            non-exclusive, non-transferable licence to access and use the Crane
            App and any connected content, materials, or information
            (collectively, "Content") solely for authorised purposes as
            determined by Crane from time to time. All other rights, titles, and
            interests in the Crane App or Content are solely owned by Crane and
            its licensors, and any other use of the Crane App or Content is
            explicitly forbidden. You hereby agree that you will not, in whole
            or in part, copy, transmit, distribute, sell, licence, reverse
            engineer, alter, publish, participate in the transfer or sale of,
            create derivative works from, or otherwise exploit any of the
            content. "Crane" and all logos related to Crane Services or
            displayed on the Crane App are either trademarks or registered marks
            of Crane or its licensors. You may not copy, imitate, or use them
            without Crane's prior written consent.
          </p>
          <p className="pb-[10px]">
            The Crane App (including, without limitation, the Content) may not
            always be totally accurate, comprehensive, or current and may also
            have technical mistakes or typographical errors, even though we aim
            to provide accurate and timely information on it. Information may
            occasionally change or be updated without prior notice in an effort
            to continue to give you the most complete and accurate information
            available. This includes information about our policies, goods, and
            services. As a result, you should always carefully verify
            information before relying on it, and any judgements you make based
            on the information in the Crane App are entirely your own, and we
            disclaim all responsibility for them. Though they may be offered as
            a convenience, links to resources created by third parties are not
            under our control. You understand and agree that the Crane App and
            any third-party materials or websites that are accessible through
            links from or to the Crane App are not our only sources for any
            information, content, or services.
          </p>
          <p className="pb-[10px]">
            You agree that, to the extent permitted by Crane from time to time,
            giving express authorization to a third party to access or connect
            to your Crane Account through that third party's product or service
            or through the Crane App does not absolve you of any of your
            obligations under this Agreement. Any third person who has access to
            your Crane account is under your complete control and is accountable
            for their actions. Furthermore, you acknowledge and agree that you
            will not hold Crane accountable for, and will defend Crane against,
            any responsibility resulting from or connected to any act or
            omission of any third party with access to your Crane Account.
          </p>
          <p className="pb-[10px]">
            You agree and represent that you will not engage in any prohibited
            business or prohibited usage as defined below in connection with
            your use of the Crane Services, your contacts with other users, or
            third parties. In order to comply with any applicable law,
            regulation, sanctions programme, legal process, or governmental
            request, we reserve the right to monitor, examine, retain, and/or
            disclose any information as needed. If we decide, in our sole
            judgement, that your account is connected to a banned use and/or a
            prohibited company, we reserve the right to immediately terminate
            and/or suspend your Crane account, prohibit transactions, or freeze
            funds.
          </p>
          <p className="pb-[10px]">
            International export restrictions and economic sanctions regulations
            may apply to how you utilise the Crane Services and Crane App. By
            using the Crane App or Crane Services to receive, sell, or store
            digital assets, you guarantee that you will abide by these
            conditions.
          </p>
          <p className="pb-[10px]">
            There is a cap on the volume you can transact or transfer using all
            Crane Services in a particular time frame, expressed in fiat
            currency (e.g., daily). Your transactional restrictions could change
            based on your KYC verification level, payment method, financial
            institution, and other elements. Please contact your financial
            institution to learn more about your restrictions.
          </p>
          <p className="pb-[10px]">
            You further represent, agree, and warrant that when accessing or
            using the services, you will not breach any contract, intellectual
            property right, or other right of a third party or constitute a
            tort. You are solely responsible for your behaviour while using our
            services. Without limiting the aforementioned generally, you
            represent, agree, and warrant that you will not:
          </p>

          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Customers shall not use circumvention or obfuscating technologies to
            conceal their IP addresses or other transaction details.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Customers shall not develop or make available any technologies that
            violate these terms or that would enable other customers to do so.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Customers shall not use the Services in any way that could hinder,
            obstruct, or otherwise negatively affect other users from using the
            services with full functionality, or that could damage, disable,
            overburden or impair the functioning of the Services in any manner;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Use the services to finance, assist, or otherwise participate in any
            unlawful gaming, fraud, money-laundering, terroristic, or other
            unlawful activity.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Use any automated tool or interface not provided by Crane to access
            the Services or to extract data, such as a robot, spider, crawler,
            scraper, or other;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Use or make an attempt to use another User Address without
            permission;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Attempt to get around any content filtering measures we use or
            access any service or part of our services that you are not
            authorised to access;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Without first obtaining our explicit permission, create any
            third-party applications that communicate with our Services;
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] pb-[20px]  ">
            <span className="xl:mr-[10px] mr-[5px] ">●</span>
            Provide erroneous, misleading, or deceptive information; promote or
            incite a third party to engage in any of the actions forbidden by
            this Section.
          </p>

          <h4 className="font-semibold text-2xl pb-[10px]">Termination</h4>
          <p className="pb-[10px]">
            Your use of any or all Crane Services may be suspended, restricted,
            or terminated by Crane, and/or (b) your Crane Account may be
            deactivated or cancelled under the following circumstances:
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            We are required to do this by a subpoena, court order, or binding
            order from a government agency that is facially valid; or
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            We have reason to believe you are using your Crane Account for an
            Illegal Purpose or business, or
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Your use of your Crane Account is being investigated, litigated, or
            subject to other legal action; and/or We believe your Account
            activity poses a higher risk of violating laws or regulations; or
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            Our service partners are powerless to bring legal action against
            you.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] pb-[20px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            You engage in any behaviour that Crane judges to be a violation of
            its regulations, including but not limited to opening numerous Crane
            accounts or exploiting any promotions that Crane may occasionally
            provide.
          </p>
          <p className="pb-[10px]">
            Unless a court order or other legal procedure prevents Crane from
            doing so, we will notify you if we suspend, close, or terminate your
            account or your use of Crane Services for any reason. You agree that
            Crane may decide to act in a certain way, such as restricting access
            to, suspending, or terminating your account, based on confidential
            standards that are crucial to the company's risk management and
            security procedures. You acknowledge that Crane is not required to
            give you information regarding its risk management and security
            practises
          </p>
          <p className="pb-[10px]">
            Unless the transfer is otherwise prohibited (i) by the law,
            including but not limited to applicable sanctions programmes, or
            (ii) by a subpoena or court order that is facially valid, you will
            be allowed to transfer digital assets or funds associated with your
            Crane Wallet within five (5) days of Crane's notice prior to account
            deactivation or cancellation. If Crane suspends or shuts your
            account or terminates your usage of the Crane App and Services for
            any reason, Crane has the right to require that you finish the
            verification procedures before allowing you to transfer or withdraw
            digital assets or fiat currency. While using the services, you
            accept and recognise that there may be discontinuity and disruption
            of the services due to network connectivity issues or other force
            majeure. Users shall make sure to provide only correct information.
            Crane does not assume any responsibility for any losses caused by
            the aforementioned situations due to your providing incorrect
            information, which results in our inability to reach out and explain
            to you the handling procedures.
          </p>
          <p className="pb-[10px]">
            We have the right to know the purpose and background of the users
            who use our products or services. Users should provide comprehensive
            and accurate information as required. If we have reasonable grounds
            to suspect that the user has provided false trading information, we
            are entitled to restrict the user's use of some or all of our
            products and services, temporarily or permanently.
          </p>
          <p className="pb-[10px]">
            Crane is an independent contractor for all purposes. Nothing in this
            Agreement shall be deemed or is intended to be deemed, nor shall it
            cause you and Crane to be treated as partners, joint ventures, or
            otherwise as joint associates for profit, or either you or Crane to
            be treated as the agent of the other.
          </p>
          <p className="pb-[10px]">
            You are responsible for maintaining adequate security and control of
            any and all IDs, passwords, hints, Bank Verification Numbers (BVNs),
            API keys, or any other codes that you use to access the Crane
            Services. Any loss or compromise of the foregoing information and/or
            your personal information may result in unauthorised access to your
            Crane Account by third parties and the loss or theft of any digital
            asset and/or funds held in your Crane Account and any associated
            accounts, including your linked bank account(s). You are responsible
            for keeping your email address and telephone number up-to-date in
            your account profile in order to receive any notices or alerts that
            we may send you. We assume no responsibility for any loss that you
            may sustain due to a compromise of account login credentials due to
            no fault of Crane and/or failure to follow or act on any notices or
            alerts that we may send to you. In the event you believe your Crane
            account information has been compromised, contact Crane Support
            immediately at &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>
            .
          </p>
          <p className="pb-[10px]">
            You are solely responsible for determining whether and how much
            taxation will be due on any transactions you make using the Crane
            Services, as well as for withholding, collecting, reporting, and
            remitting the necessary amount of taxation to the relevant taxing
            authorities. Your Crane account contains access to your transaction
            history.
          </p>
          <p className="pb-[10px]">
            Crane may be required by law to report funds as unclaimed property
            to the relevant jurisdiction if it is holding funds in your account
            but cannot reach you or find any evidence of your use of the
            services for a number of years. If this happens, Crane will make an
            effort to contact you at the address listed in our records, but if
            Crane is unable to do so, it may be necessary to submit any
            associated cash to the relevant authority as unclaimed property.
            Crane maintains the right, in accordance with relevant legislation,
            to take a dormancy fee or other administrative costs from any
            unclaimed monies.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">8. Miscellaneous</h4>
          <p className="pb-[10px]">
            <b>Force Majeure.</b> We shall not be liable for delays, failure in
            performance or interruption of service which result directly or
            indirectly from any cause or condition beyond our reasonable
            control, including but not limited to, any delay or failure due to
            any act of God, pandemic, epidemic, act of civil or military
            authorities, Governmental regulation or restriction, act of
            terrorists, civil disturbance, war, strike or other labour dispute,
            fire, interruption in telecommunications or Internet services or
            network provider services, failure of equipment and/or software,
            other catastrophe or any other occurrence which is beyond our
            reasonable control and shall not affect the validity and
            enforceability of any remaining provisions.
          </p>
          <p className="pb-[10px]">
            <b> Indemnification:</b> If you have a dispute with one or more
            users of the Crane services, you release Crane, its affiliates and
            service providers, and each of their respective officers, directors,
            agents, joint venturers, employees, and representatives from any and
            all claims, demands, and damages (actual and consequential) of every
            kind and nature arising out of or in any way connected with such
            disputes. You agree to fully indemnify and hold Crane, its
            affiliates and service providers, and each of their respective
            officers, directors, agents, joint venturers, employees, and
            representatives harmless from any claim or demand (including
            attorneys' fees and any fines, fees, or penalties imposed by any
            regulatory authority) arising out of or related to your breach of
            this Agreement or your violation of any law, rule, or regulation, or
            the rights of any third party.
          </p>
          <p className="pb-[10px]">
            <b> Limitation of Liability:</b>You indemnify and hold Crane
            harmless against any claims, demands, and damages, whether direct,
            indirect, consequential, special, or any other damages of any kind,
            including, but not limited to, loss of use, loss of profits, or loss
            of data, whether in an action in contract, tort (including but not
            limited to negligence), or otherwise.
          </p>
          <p className="pb-[10px]">
            In no event shall Crane, its affiliates and service providers, or
            any of their respective officers, directors, agents, joint
            venturers, employees or representatives, be liable (a) for any
            amount greater than the value of the supported digital asset on
            deposit in your crane account or (b) for any lost profits or any
            special, incidental, indirect, intangible, or consequential damages,
            whether based in contract, tort, negligence, strict liability, or
            otherwise, arising out of or in connection with authorised or
            unauthorised use of the crane app or crane services, or this
            agreement, even if an authorised representative of Crane has been
            advised of or knew or should have known of the possibility of such
            damages. This means, by way of example only (and without limiting
            the scope of the previous sentence), that if you claim that Crane
            failed to process a sell transaction properly, your damages are
            limited to no more than the value of the supported digital assets at
            issue in the transaction, and that you may not recover for lost
            profit. Crane services are provided on an "as is" and "as available"
            basis without any representation or warranty, whether express,
            implied, or statutory. To the maximum extent permitted by applicable
            law, Crane specifically disclaims any implied warranties of title,
            merchantability, fitness for a particular purpose, and/or
            non-infringement. Crane does not make any representations or
            warranties that access to the app, any part of the crane services,
            or any of the materials contained therein will be continuous,
            uninterrupted, timely, or error-free.
          </p>
          <p className="pb-[10px]">
            <b>Historical Price Data:</b>The historical digital asset pricing
            data that is available on the app and website is provided "as is,"
            and Crane makes no claims as to its accuracy or completeness. Crane
            will use reasonable efforts to expedite the processing of requests
            for electronic debits and credits involving bank accounts, but Crane
            makes no representations or warranties regarding the length of time
            it will take to complete processing, which depends on numerous
            factors outside of our control.
          </p>
          <p className="pb-[10px]">
            <b> Computer Viruses:</b> We will not be held responsible in any way
            for any harm or disruption brought on by computer viruses, spyware,
            scareware, Trojan horses, worms, or other malware that might affect
            your phone, computer, or other equipment, or by any phishing,
            spoofing, or other attacks. We suggest using a trustworthy, easily
            accessible virus prevention and detection programme on a frequent
            basis. Also, you should be aware that email and SMS services are
            susceptible to phishing and spoofing attempts, so you should be
            cautious when analysing messages that appear to be from Crane. If
            you have any doubts as to the validity of any communication or
            notice, always go into your Crane Account via the Crane App to
            review any transactions or necessary actions.
          </p>
          <p className="pb-[10px]">
            <b> Entire Agreement:</b>This Agreement, the Privacy Policy, and the
            Appendices are all incorporated by reference and constitute the
            entire understanding and agreement between you and Crane with
            respect to the subject matter hereof. This Agreement supersedes all
            prior discussions, agreements, and understandings of any kind
            (including, without limitation, any earlier versions of this
            Agreement) and of any and all nature between and among you and
            Crane. This agreement's section headings are included purely for
            convenience and have no bearing on how any of its provisions should
            be read or understood.
          </p>
          <p className="pb-[10px]">
            <b> Amendments:</b> We reserve the right to change or modify this
            agreement at any time, and the revised agreement will take effect
            when it is posted on the Crane App, website, or emailed to you. You
            may stop using the services and ask to have your account closed if
            you disagree with any such modification. This is your only legal
            recourse. If we modify or terminate Crane Services or suspend or
            terminate your access to Crane Services, you agree that we won't be
            held responsible for you or any third parties unless clearly stated
            otherwise in this agreement. We endeavour to notify you in advance
            via our website, app, and/or email if the amended agreement contains
            a major change before it takes effect.
          </p>
          <p className="pb-[10px]">
            <b> Assignment:</b>Any rights and/or licences granted to you under
            this agreement may not be assigned. Without limitation, we retain
            the right to assign our rights to any subsidiaries or affiliates of
            Crane as well as to any firm or successor in interest of any
            business connected to Crane Services. Any attempted transfer or
            assignment in violation of this clause is invalid. This agreement
            will bind and insure the parties, their successors, and allowed
            assigns, subject to the above.
          </p>
          <p className="pb-[10px]">
            <b>Severability:</b> The validity or enforceability of the remaining
            provisions of this agreement will not be impacted if any provision
            of this agreement is found to be invalid or unenforceable under any
            rule, law, or regulation of any local, state, or federal
            governmental agency. Instead, the provision will be changed and
            interpreted to the greatest extent permitted by applicable law in
            order to achieve its intended goals.
          </p>
          <p className="pb-[10px]">
            <b> Change of Control:</b>We retain the right, in any of these
            situations, to transfer or assign the information we have obtained
            from you as part of any merger, acquisition, sale, or other change
            of control. This includes the case where Crane is bought out by or
            merged with a third-party entity.
          </p>
          <p className="pb-[10px]">
            <b>Survival:</b> All terms of this agreement that, by their nature,
            continue after its expiration or termination, such as those relating
            to suspension or termination, cancellation of a Crane Account,
            unpaid debts to Crane, general use of the Crane App, disagreements
            with Crane, and general provisions, shall continue to apply after
            the expiration or termination of this Agreement
          </p>
          <p className="pb-[10px]">
            <b>Governing Law:</b>This agreement will be governed by the laws in
            force in the Federal Republic of Nigeria
          </p>
          <p className="pb-[10px]">
            <b>Complaints and Disputes:</b> If you have any feedback, questions,
            or complaints, contact us via &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>
            . In order for us to identify you, your Crane Account, and the
            transaction about which you have comments, questions, or concerns,
            please be sure to provide your name, address, and any other
            information we may require when you get in touch with us.
          </p>
          <p className="pb-[10px]">
            If you have a complaint, please explain the situation, your
            preferred course of action, and any other details you deem
            important. If you contact us by email at &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>{" "}
            or on our customer service homepage, we will acknowledge your
            complaint within 24 hours of its receipt.
          </p>
          <p className="pb-[10px]">
            Before bringing any claim before a court with the appropriate
            jurisdiction, you consent to using the complaints procedure. Failing
            to do so may be taken as proof of your unwillingness to negotiate a
            resolution and/or the vexatiousness of the complaint.
          </p>
        </div>

        <h4 className="font-semibold text-2xl">
          APPENDIX 1: PROHIBITED USE, PROHIBITED BUSINESSES AND CONDITIONAL USE
        </h4>
        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">
            1. Prohibited Use
          </h4>
          <p className="pb-[10px]">
            You may not use your Crane Account to engage in the following
            categories of activity ("Prohibited Uses"). The specific types of
            use listed below are representative but not exhaustive. If you are
            uncertain as to whether or not your use of Crane Services involves a
            prohibited use or have questions about how these requirements apply
            to you, please contact us at &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>
            . By opening a Crane Account, you confirm that you will not use your
            account to do any of the following:
          </p>

          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Unlawful Activity:</b> Activity that would violate, or assist in
            violating, any law, statute, ordinance, or regulation; sanctions
            programmes administered in the country where Crane conducts business
            (Nigeria); or which would involve the proceeds of any unlawful
            activity; publishing, distributing, or disseminating any unlawful
            material or information
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Abusive Activity: </b>Actions which impose an unreasonable or
            disproportionately large load on our infrastructure, or
            detrimentally interfere with, intercept, or expropriate any system,
            data, or information; transmit or upload any material to the Crane
            App that contains viruses, trojan horses, worms, or any other
            harmful or deleterious programmes; attempt to gain unauthorised
            access to the Crane App, other Crane Accounts, computer systems or
            networks connected to the Crane App, through password mining or any
            other means; use Crane Account information of another party to
            access or use the Crane App, except in the case of specific
            merchants and/or applications that are specifically authorised by a
            user to access such user's Crane Account and information; or
            transfer your account access or rights to your account to a third
            party, unless by operation of law or with the express permission of
            Crane
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Abuse Other Users: </b>Interfere with another individual's or
            entity's access to or use of any Crane Services; defame, abuse,
            extort, harass, stalk, threaten, or otherwise violate or infringe
            the legal rights (such as, but not limited to, rights of privacy,
            publicity, and intellectual property) of others; incite, threaten,
            facilitate, promote, or encourage hate, racial intolerance, or
            violent acts against others; harvest or otherwise collect
            information from the Crane App about others, including without
            limitation email addresses, without proper consent
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Fraud:</b> Activity that operates to defraud Crane, Crane users,
            or any other person; provide any false, inaccurate, or misleading
            information to Crane.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Gambling:</b> Lotteries; bidding fee auctions; sports
            forecasting or odds-making; fantasy sports leagues with cash prizes;
            internet gaming; contests; sweepstakes; games of chance
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Intellectual Property Infringement: </b>Engage in transactions
            involving items that infringe or violate any copyright, trademark,
            right of publicity or privacy or any other proprietary right under
            the law, including but not limited to sales, distribution, or access
            to counterfeit music, movies, software, or other licenced materials
            without the appropriate authorization from the rights holder; use of
            Crane intellectual property, name, or logo, including use of Crane
            trade or service marks, without express consent from Crane or in a
            manner that otherwise harms Crane or the Crane brand; any action
            that implies an untrue endorsement by or affiliation with Crane.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">
            2. Prohibited Businesses
          </h4>
          <p className="pb-[10px]">
            In addition to the Prohibited Uses described above, the following
            categories of businesses, business practises, and sale items are
            barred from Crane Services ("Prohibited Businesses"). Most
            prohibited business categories are imposed by Card Network rules or
            the requirements of our banking providers or processors. The
            specific types of use listed below are representative but not
            exhaustive. If you are uncertain as to whether or not your use of
            Crane Services involves a prohibited business or have questions
            about how these requirements apply to you, please contact us at
            &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>
            .
          </p>
          <p className="pb-[10px]">
            By opening a Crane Account, you confirm that you will not use Crane
            Services in connection with any of the following businesses,
            activities, practises, or items:
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Investment and Credit Services:</b> Securities brokers; mortgage
            consulting or debt reduction services; credit counselling or repair;
            real estate opportunities; investment schemes
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Restricted Financial Services:</b> Check cashing, bail bonds,
            collections agencies
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Intellectual Property or Proprietary Rights Infringement: </b>
            Sales, distribution, or access to counterfeit music, movies,
            software, or other licenced materials without the appropriate
            authorization from the rights holder
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Counterfeit or Unauthorised Goods:</b> Unauthorised sale or
            resale of brand-name or designer products or services; sale of goods
            or services that are illegally imported or exported or that are
            stolen.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Regulated Products and Services:</b> Marijuana dispensaries and
            related businesses; sale of tobacco, e-cigarettes, and e-liquid;
            online prescription or pharmaceutical services; age-restricted goods
            or services; weapons and munitions; gunpowder and other explosives;
            fireworks and related goods; toxic, flammable, and radioactive
            materials.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Drugs and Drug Paraphernalia:</b> Sale of narcotics, controlled
            substances, and any equipment designed for making or using drugs,
            such as bongs, vaporizers, and hookahs
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Pseudo-Pharmaceuticals:</b> Pharmaceuticals and other products
            that make health claims that have not been approved or verified by
            the applicable local and/or national regulatory body.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Substances designed to mimic illegal drugs:</b> Sale of a legal
            substance that provides the same effect as an illegal drug (e.g.,
            salvia, kratom).
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Adult Content and Services:</b> Pornography and other obscene
            materials (including literature, imagery, and other media); sites
            offering any sexually-related services such as prostitution,
            escorts, pay-per view, adult live chat features.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Multi-level Marketing:</b> Pyramid schemes, network marketing,
            and referral marketing programs.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Unfair, predatory, or deceptive practices: </b>Investment
            opportunities or other services that promise high rewards; Sale or
            resale of a service without added benefit to the buyer; resale of
            government offerings without authorization or added value; sites
            that we determine in our sole discretion to be unfair, deceptive, or
            predatory towards consumers
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>High risk businesses:</b> any businesses that we believe pose
            elevated financial risk, legal liability, or violate card network or
            bank policies.
          </p>
        </div>

        <div className="py-[30px]">
          <h4 className="font-semibold text-2xl pb-[10px]">
            3. Conditional Use
          </h4>
          <p className="pb-[10px]">
            Express written consent and approval from Crane must be obtained
            prior to using Crane Services for the following categories of
            business and/or use ("Conditional Uses"). Consent may be requested
            by contacting &nbsp;
            <a
              // target="_blank"
              href="mailto:support@usecrane.co"
              className="underline text-[blue]"
              // rel="noreferrer"
            >
              support@usecrane.co
            </a>
            . Crane may also require you to agree to additional conditions, make
            supplemental representations and warranties, complete enhanced
            on-boarding procedures, and operate subject to restrictions if you
            use Crane Services in connection with any of the following
            businesses, activities, or practices:
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Money Services:</b> Money transmitters; Digital Currency
            transmitters; currency or Digital Currency exchanges or dealers;
            gift cards; prepaid cards; sale of in-game currency unless the
            merchant is the operator of the virtual world; act as a payment
            intermediary or aggregator; or otherwise resell any of the Crane
            Services.
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b>Charities:</b> Acceptance of donations for non-profit enterprises
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Games of Skill:</b> Games that are not defined as gambling under
            this Agreement or by law but which require an entry fee and award a
            prize
          </p>
          <p className="xl:px-[30px] px-0 mt-[10px] ">
            <span className="xl:mr-[10px] mr-[5px]  ">●</span>
            <b> Religious or spiritual organizations:</b> Operation of a
            for-profit religious or spiritual organisation.
          </p>
        </div>

        <h4 className="font-semibold text-2xl pb-[20px]">
          APPENDIX 2: VERIFICATION PROCEDURES AND LIMITS
        </h4>
        <p className="pb-[10px]">
          In order to safeguard Crane and the community against fraudulent users
          and to maintain accurate records of Crane's customers, Crane uses
          multi-level systems and procedures to gather and verify information
          about you. Your ability to use one or more Crane Services, as well as
          any restrictions on them, such as withdrawal and trading caps, may be
          based on the identification data and/or identification documentation
          you give Crane, as well as restrictions imposed by your financial
          institution.
        </p>
        <p className="pb-[20px]">
          Before allowing you to utilise any Crane Services and/or before
          allowing you to conduct transactions beyond a particular volume cap,
          Crane may need you to supply or verify additional information or to
          wait a specific length of time after a transaction has been completed.
        </p>

        <h4 className="font-semibold text-2xl pb-[20px]">
          APPENDIX 3: E-SIGN DISCLOSURE AND CONSENT
        </h4>
        <p className="pb-[10px]">
          This policy describes how Crane delivers communications to you
          electronically. We may amend this policy at any time by providing a
          revised version on our website. The revised version will be effective
          at the time we post it. We will provide you with prior notice of any
          material changes via our website.
        </p>
        <div className="py-[15px]">
          <div className="">
            <h4 className="font-semibold text-2xl pb-[10px]">
              1. Electronic Delivery of Communications
            </h4>
            <p className="pb-[3px]">
              You agree and consent to receive electronically all
              communications, agreements, documents, notices, and disclosures
              (collectively, "Communications") that we provide in connection
              with your Crane Account and your use of Crane Services.
              Communications include:
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Terms of use and policies you agree to, including updates to these
              agreements or policies;
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Account details, history, updates, transaction receipts,
              confirmations, and any other Account or transaction information;
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Legal, regulatory, and tax disclosures or statements we may be
              required to make available to you; and
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Responses to claims or customer support inquiries filed in
              connection with your account
            </p>
            <p className="pb-[3px]">
              We will provide these communications to you by posting them on the
              Crane website, emailing them to you at the primary email address
              listed in your Crane profile, communicating with you via instant
              chat, and/or through other electronic communication such as text
              message.
            </p>
          </div>
        </div>

        <div className="py-[15px]">
          <div className="">
            <h4 className="font-semibold text-2xl pb-[10px]">
              2. Hardware and Software Requirements
            </h4>
            <p className="pb-[3px]">
              In order to access and retain electronic communications, you will
              need the following computer hardware and software:
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>A device with an
              Internet connection;
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>A current web
              browser that includes 128-bit encryption (e.g. Internet Explorer
              version 9.0 and above, Firefox version 3.6 and above, Chrome
              version 31.0 and above, or Safari 7.0 and above) with cookies
              enabled
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>A valid email
              address (your primary email address on file with Crane); and
            </p>
            <p className="xl:px-[30px] px-0 mt-[10px] pb-[20px] ">
              <span className="xl:mr-[10px] mr-[5px]  ">●</span>
              Sufficient storage space to save past communications or an
              installed printer to print them.
            </p>
            <h4 className="font-semibold text-2xl pb-[10px]">
              How to Withdraw Your Consent
            </h4>
            <p className="pb-[3px]">
              By sending us an email at support@usecrane.co, you can revoke your
              permission to receive communications electronically. Crane
              maintains the right to immediately cancel your account or impose
              additional fees for paper copies if you refuse to give consent to
              receive communications electronically or revoke such consent.
            </p>
          </div>
        </div>

        <div className="py-[15px]">
          <div className="">
            <h4 className="font-semibold text-2xl pb-[10px]">
              3. Updating your Information
            </h4>
            <p className="pb-[3px]">
              It is your obligation to give us a real, correct, and
              comprehensive email address, as well as all other necessary
              contact information, and to keep this information up-to-date. You
              acknowledge and agree that if Crane sends you an electronic
              communication but you are unable to receive it because your
              primary email address is incomplete, outdated, blocked by your
              service provider, or for any other reason, Crane shall be regarded
              as having given the communication to you
            </p>
            <p className="pb-[3px]">
              You may update your information by logging into your account and
              visiting account settings or by contacting our support team via
              email at{" "}
              <a
                // target="_blank"
                href="mailto:support@usecrane.co"
                className="underline text-[blue]"
                // rel="noreferrer"
              >
                support@usecrane.co
              </a>
            </p>
          </div>
        </div>
        <p>Lagos, Nigeria</p>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
