
// import { motion } from "framer-motion";
// import { fadeIn, staggerContainer, textContainer, textVariant2 } from "../utils/motion";

const Feature = ({ title, subTitle, longText,
  maxWidth, whiteSpace}) => {
  return (
    <div
    // variants={fadeIn("up", "tween", 0.3, 1)}
    >
      <p
        //  variants={staggerContainer}
        //  initial="hidden"
        //  whileInView="show"
        //  viewport={{ once: false, amount: 0.25 }}
        className="text-[#419822] capitalize md:text-xl  mx-auto text-xs font-medium"
      >
        {Array.from(title).map((letter, index) => (
          <span key={index}>{letter === " " ? "\u00A0" : letter}</span>
        ))}
      </p>
      <p className="py-6 md:p-10 lg:text-[4rem] text-[1.75rem] mx-auto font-extrabold md:font-bold px-14 leading-tight" style={{ maxWidth: maxWidth, whiteSpace: whiteSpace  }}>
        {subTitle}
      </p>
      <p
        // variants={fadeIn("up", "tween", 0.2, 1)}
        // initial="hidden"
        // animate="show"
        className="max-w-[38rem] leading-normal px-8 md:px-9  mx-auto font-medium text-sm md:text-xl"
      >
        {longText}
      </p>
    </div>
  );
};

export default Feature;
