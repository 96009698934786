import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ThirdSection = () => {
  return (
    <div className="bg-[#2F2E31] xl:pb-[240px] xl:pt-[145px] py-[72px] px-[17px] mb-[63px] lg:mb-[74px]">
      <div className="lg:w-[85%] mx-auto">
        <h2 className="text-[1.75rem] xl:text-[5rem] font-extrabold text-white  w-full leading-normal xl:leading-tight xl:w-[1008px] mb-[64px] xl:mb-[68px]">
          You sleep well knowing you are in safe hands with our auto-convert
          feature
        </h2>
        <div className="flex justify-between flex-col xl:items-center xl:flex-row gap-[60px]">
          <div className="flex flex-col gap-[24px] xl:gap-[64px]">
            <p className=" text-base font-bold xl:text-[1.75rem] text-left text-white leading-tight">
              <span className="text-[#1AD05D]"> Download</span> and sign up on
              the Crane App
            </p>
            <p className=" text-base font-bold xl:text-[1.75rem] text-left text-white leading-tight">
              Tap AUTO-CONVERT button on the homepage
            </p>
            <p className=" text-base font-bold xl:text-[1.75rem] text-left text-white leading-tight">
              Tap 'Create New'
            </p>
            <p className=" text-base font-bold xl:text-[1.75rem] text-left text-white leading-tight">
              Choose the type of asset, conversion timing, and volume desired
            </p>
            <p className=" text-base font-bold xl:text-[1.75rem] text-left text-white leading-tight">
              Confirm by clicking 'Create Order'
            </p>
          </div>
          <div className="w-[241px] h-[492px] xl:w-[355px] xl:h-[722px] flex-none mx-auto">
            <LazyLoadImage
              src="https://res.cloudinary.com/dpqxraalv/image/upload/v1711635788/Autosell_Mockup_2x_taybo9.png"
              alt="A smartphone screen showing an auto-convert feature for cryptocurrency. The screen displays two pending conversions, one for $15 and one for $500, with specified conditions for triggering the conversions based on exchange rates."
              title="A smartphone screen showing an auto-convert feature for cryptocurrency. The screen displays two pending conversions, one for $15 and one for $500, with specified conditions for triggering the conversions based on exchange rates."
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
