
import { motion } from "framer-motion";
import Logo from "./Logo";
import NavbarButtons from "./NavbarButtons";
import NavbarOptions from "./NavbarOptions";
import { navVariants } from "../../utils/motion";

const Navbar = ({ bgColor, textColor, openModal, openNigeriaModal}) => {
  return (
    <nav className={`bg-${bgColor}`}>
      <motion.div
        variants={navVariants}
        initial="hidden"
        animate="show"
        className={`bg-${bgColor} lg:w-[85%] mx-auto p-4 lg:p-10 flex items-center justify-between xl:w-11/12 `}
      >
        <Logo />
        <NavbarOptions textColor={textColor} />
        <NavbarButtons openModal={openModal} openNigeriaModal={openNigeriaModal} />
      </motion.div>
    </nav>
  );
};

export default Navbar;
