import React from "react";
import ImageShuffler from "./ImageShuffler";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const SecondSection = () => {
  return (
    <div className="xl:pb-[240px] xl:pt-[145px] py-[76px] px-[17px] ">
      <div className="lg:w-[85%] mx-auto xl:rounded-[140px] rounded-[40px] md:h-[1100px] xl:h-[1545px] h-[675px] overflow-hidden bg-[#1D1D1D] text-white  flex flex-col align-center pt-[63px] xl:pt-[120px]">
        <div className="px-[20px] flex justify-center items-center gap-[16px] text-center flex-col xl:gap-[12px]  mx-auto">
          <h3 className="xl:font-bold md:text-[2rem] xl:text-[3rem] 2xl:text-[5rem] text-[1.75rem] font-extrabold">
            Over $1M
          </h3>
          <p className="mb-[80px] xl:mb-[176px] xl:w-[918px] sm:w-[310px] font-medium text-[1.25rem] xl:text-[2.25rem] 2xl:text-[3.25rem]">
            worth of crypto converted into Naira—seamlessly, instantly.
          </p>
        </div>

        <div className="px-[20px] flex justify-center items-center gap-[16px] text-center flex-col xl:gap-[12px] mx-auto">
        <h3 className="xl:font-bold md:text-[2rem] xl:text-[3rem] 2xl:text-[5rem] text-[1.75rem] font-extrabold">
            Join 10k+
          </h3>
          <p className="mb-[70px] xl:mb-[111px] xl:w-[918px] sm:w-[310px] font-medium text-[1.25rem] xl:text-[2.25rem] 2xl:text-[3.25rem]">
            freelancers, businesses, and crypto enthusiasts who trust Bank
            Direct to turn crypto into cash in a snap.
          </p>
        </div>

        {/* ImageShuffler should be fully contained within this div */}
        <div className="w-full max-w-[90%] sm:max-w-[80%] md:max-w-[75%] lg:max-w-[70%] xl:max-w-[65%] mx-auto">
          <ImageShuffler />
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
