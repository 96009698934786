import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ThirdSection = () => {
  return (
    <div className=" xl:pt-[145px] pt-[76px] pb-[100px] px-[17px]">
      <div className="lg:w-[85%] mx-auto">
        <h3 className="justify-start flex leading-tight  md:text-[5rem] sm:w-[350px] text-[1.75rem] md:w-[882px] font-bold text-[#050B05] text-left">
        Say goodbye to delayed payments
        </h3>

        <div className=" flex flex-col lg:flex-row justify-between items-start mt-[64px] md:mt-[100px] gap-[44px] md:gap-[50px] xl:gap-[108px]">
          {/*  1*/}
          <div className=" flex flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1728392857/Icons-Crypto_2x_evjnev.png"
                  alt=" Automate your trade"
                  title=" Automate your trade"
                  className="w-full h-full"
                />
              </div>
            </div>

            <div className=" flex flex-col justify-start items-start gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
              Instant crypto conversion
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
              Not crypto savvy? No problem. We convert your crypto to Naira and send it straight into your bank account. No fuss, just funds.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className=" flex flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image*/}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1728392857/Icons-Fast_2x_jq2o5i.png"
                  alt=" Easy to use"
                  title=" Easy to use"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start  gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
              Lightning-fast payments
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
              Why wait days to get paid of your work? With Bank Direct, payments hit your bank in 2 minutes—faster than your favourite song ends!
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className=" flex flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1728392856/Icons-International_2x_nd5e2k.png"
                  alt="Never miss out"
                  title="Never miss out"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start  gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
              Easy International payments
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
              Don’t stress over international payments. Receive crypto from anywhere, and we’ll handle the conversion and deposit—so you can focus on what matters.
              </p>
            </div>
          </div>

          </div>
          <div className=" flex flex-col lg:flex-row justify-between items-start mt-[64px] md:mt-[100px] gap-[44px] md:gap-[50px] xl:gap-[108px]">
            {/* 4 */}
            <div className=" flex flex-1 flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1728392857/Icons-Fees_2x_c3mdj3.png"
                  alt="Never miss out"
                  title="Never miss out"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start  gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
              Low conversion fees
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
              Your hard-earned money belongs to you. Enjoy instant crypto-to-Naira conversion for just a 2% fee—no hidden charges, no surprises. Keep more money in your pocket!
              </p>
            </div>
          </div>
            {/* 5*/}
            <div className=" flex flex-1 flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
            {/* image */}
            <div className="flex 2xl:flex-row flex-col justify-start items-center gap-[12px] md:gap-[32px]">
              <div className="w-[50px] h-[50px] xl:w-[80px] xl:h-[80px] flex-none">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1728392856/Icons-Secure_2x_ukwocs.png"
                  alt="Never miss out"
                  title="Never miss out"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className=" flex flex-col justify-start items-start  gap-[16px] md:gap-[20px]">
              <h4 className="text-[#050B05] font-bold  text-base text-left md:text-xl">
              Safe and secure payments
              </h4>
              <p className="text-[#050B05] font-medium  text-sm text-left md:text-xl">
              Your financial safety is our priority. From secure transfers to protecting your personal data, you’re well-protected.
              </p>
            </div>
          </div>
            {/* 6*/}
            <div className=" flex flex-1 flex-col  justify-start items-start gap-[36px] xl:gap-[64px] 2xl:w-[392px]">
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
