import React from "react";
import { motion } from "framer-motion";

const FourthSection = () => {
  return (
    <div className="relative">
      <div
        className="bg-cover bg-center bg-no-repeat h-[321px] w-[95%] rounded-[20px] xl:rounded-[0px] lg:w-[85%] mx-auto mb-[80px] xl:mb-[147px]"
        style={{
          backgroundImage:
            'url("https://res.cloudinary.com/dpqxraalv/image/upload/v1728565552/Grid_Pattern_BG_2x_gulcpo.png")',
        }}
      ></div>

      {/* Overlay Text */}
      <div className="absolute inset-0 flex items-center xl:flex-row flex-col justify-center mx-auto gap-[95px] xl:gap-[0px] 2xl:gap-[95px]">
        <div className="flex xl:justify-start items-center xl:items-start justify-center flex-col gap-[20px] px-[30px] sm:px-[40px] text-center xl:text-left">
          <h4 className="text-[1.75rem] font-extrabold">Get started today</h4>
          <p className="max-w-[690px] sm:text-[1rem] md:text-[1.25rem] text-[#050B05] text-[0.75rem] font-medium min-w-[320px]">
            Create an account instantly and start accepting crypto payments as
            Naira, straight to your local bank account.
          </p>
        </div>

        {/* Button */}
        <a
          href="https://crane.onelink.me/QQEs/zz9a92a7"
          className="bg-[black] rounded-xl py-[22px] px-[65px] flex font-bold md:text-base text-sm cursor-pointer items-center justify-center text-white"
        >
          <motion.button
            whileHover={{
              scale: 1.1,
            }}
          >
            Sign up&nbsp;
            <span className="inline-block text-[#1AD05D]">- it's easy</span>
          </motion.button>
        </a>
      </div>
    </div>
  );
};

export default FourthSection;
