const Accordion = ({ title, content, index, activeIndex, setActiveIndex }) => {
  const isOpen = index === activeIndex;

  const toggleAccordion = () => {
    setActiveIndex(isOpen ? -1 : index);
  };

  return (
    <div
      className={`py-8  ${
        isOpen
          ? "border-[#2F2E31] px-2 md:px-3  border rounded-[12px] mx-2 md:mx-3 bg-[#1D1C20]"
          : "px-4 md:px-6"
      }`}
    >
      <button
        className="w-full flex items-start mx-auto justify-between text-left gap-4 font-semibold rounded-md transition-flex duration-[0.7s] ease-out-flex"
        onClick={toggleAccordion}
        initial={{ opacity: 1, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <span className="text-sm md:text-xl font-semibold text-white">
          {title}
        </span>
        <span>
          {isOpen ? (
            <svg
              id="Icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <rect
                id="Rectangle_149521"
                data-name="Rectangle 149521"
                width="22"
                height="22"
                fill="none"
              />
              <circle
                id="Ellipse_9575"
                data-name="Ellipse 9575"
                cx="9"
                cy="9"
                r="9"
                transform="translate(3 3)"
                fill="none"
                stroke="#14f195"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_10102"
                data-name="Path 10102"
                d="M88,115.75,91.75,112l3.75,3.75"
                transform="translate(-79.75 -101.5)"
                fill="none"
                stroke="#14f195"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          ) : (
            <svg
              id="Icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <rect
                id="Rectangle_149521"
                data-name="Rectangle 149521"
                width="24"
                height="24"
                fill="none"
              />
              <circle
                id="Ellipse_9575"
                data-name="Ellipse 9575"
                cx="9"
                cy="9"
                r="9"
                transform="translate(3 3)"
                fill="none"
                stroke="#14f195"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                id="Path_10102"
                data-name="Path 10102"
                d="M88,112l3.75,3.75L95.5,112"
                transform="translate(-79.75 -102.25)"
                fill="none"
                stroke="#14f195"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          )}
        </span>
      </button>
      {/* {isOpen && (
        <div
          className="p-2 mt-2 text-white"
        >
          {content.map((paragraph, idx) => (
            <ul
              key={idx}
              className="list-none mb-4 text-sm md:text-xl font-medium text-white leading-relaxed"
            >
              {paragraph.map((bulletPoint, bulletIndex) => (
                <li
                  key={bulletIndex}
                  className="mb-6 text-[#1AD05D] font-regular text-xs lg:text-base leading-relaxed"
                >
                  {bulletPoint}
                </li>
              ))}
            </ul>
          ))}
        </div>
      )} */}
      {isOpen && (
        <div className="p-2 mt-2 text-white">
          {content.map((paragraph, idx) => (
            <div
              key={idx}
              className="mb-4 text-sm md:text-xl font-medium text-white leading-relaxed"
            >
              {paragraph.map((bulletPoint, bulletIndex) => (
                <p
                  key={bulletIndex}
                  className="mb-6 text-[#1AD05D] font-regular text-xs lg:text-base leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: bulletPoint }}
                ></p>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
