import { LazyLoadImage } from "react-lazy-load-image-component";

const Logo = () => {
  return (
    <div className="cursor-pointer md:w-[135px] md:h-[45px]  w-[87px] h-[29px]">
      <LazyLoadImage
        src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014584/CraneLogo_szdj2d.svg"
        alt="Crane app Logo"
        title="Crane app Logo"
        className="w-full h-full"
      />
    </div>
  );
};

export default Logo;

