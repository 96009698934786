import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import getCountry from "../../utils/getCountry";
import { Link } from "react-router-dom";
import { submenulinks } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NavbarButtons = ({ openModal, openNigeriaModal }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const [country, setCountry] = useState(null);

  // Add an event listener to handle the scroll behavior
  useEffect(() => {
    const handleScroll = (e) => {
      if (click) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add the event listener when the navbar is open
    if (click) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.addEventListener("scroll", handleScroll);
    } else {
      // Remove the event listener and reset styles when the modal is closed
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
      document.body.removeEventListener("scroll", handleScroll);
    }

    return () => {
      // Cleanup the event listener when the component unmounts
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [click]);

  useEffect(() => {
    // Fetch the user's country when the component mounts
    getCountry().then((result) => {
      setCountry(result);
    });
  }, []);

  const [openMenu, setOpenMenu] = useState(false);
  const openSubMenu = () => {
    setOpenMenu((prevState) => !prevState);
    // closeMobileMenu();
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      {country && country !== "" && country !== "NG" && (
        <motion.div whileHover={{ scale: 1.1 }}>
          <button
            className="py-2 px-4 text-xs md:text-base transition duration-300 rounded-3xl border-2 border-[#050B05] bg-white whitespace-nowrap"
            onClick={openModal}
          >
            Early access
          </button>
        </motion.div>
      )}

      <div
        onClick={handleClick}
        className=" cursor-pointer md:hidden block w-[32px] h-[32px]"
      >
        {!click ? (
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016721/Menu_ny2kns.png"
            alt="menu-img"
            title="menu-img"
            className=" w-full h-full "
          />
        ) : (
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014275/Button-Menu_yqxbx0.svg"
            alt="close-icon"
            title="close-icon"
            className=" w-full h-full "
          />
        )}
      </div>

      <AnimatePresence>
        {click && (
          <motion.div
            initial={{ opacity: 0, x: "-100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "-100%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="fixed  flex
        flex-col
        gap-4
        bottom-0
        left-0
        top-[70px]
        w-full
        h-full
        bg-[#050B05]
        z-50
        items-center
        justify-center
        sm:hidden"
          >
            <ul className="justify-start flex flex-col gap-10 w-full h-full items-start text-left ml-[20px]  mt-[80px]">
              <li onClick={openSubMenu} className="w-full">
                <div
                  className={`cursor-pointer flex justify-between items-center text-white font-semibold duration-300 transition-all text-base ${
                    !openMenu
                      ? "border-b border-gray-50 border-opacity-[10%] pb-[24px]"
                      : "border-0"
                  }`}
                >
                  Features
                  <div className="w-[24px] h-[24px] flex justify-center items-center mr-[20px]">
                    {openMenu ? (
                      <LazyLoadImage
                        src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708603119/Dropdown_cvnln8.svg"
                        alt="dropdown icon"
                        title="dropdown icon"
                        className="w-full h-full"
                      />
                    ) : (
                      <LazyLoadImage
                        src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1708607401/Dropdown_1_h9koqx.svg"
                        alt="dropdown icon"
                        title="dropdown icon"
                        className="w-full h-full"
                      />
                    )}
                  </div>
                </div>

                {openMenu && (
                  <div className=" h-[350px] flex flex-col justify-between bg-[#050B05] top-full left-0 pt-[44px] pb-[24px]  border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]">
                    {submenulinks?.map((menu) => (
                      <Link to={menu.url}>
                        <div
                          className="flex gap-[12px] justify-start items-start"
                          key={menu.id}
                        >
                          <div className="w-[36px] h-[36px] flex-none">
                            <LazyLoadImage
                              src={menu.mobileimage}
                              alt={menu.title}
                              className=" w-full h-full "
                            />
                          </div>
                          <div className=" flex flex-col gap-[5px] items-start justify-start w-[75%]">
                            <h4 className="font-bold text-sm text-[#fff] ">
                              {menu.title}
                            </h4>
                            <p className="font-medium text-xs text-[#fff] ">
                              {menu.text}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </li>

              <a
                href="https://blog.usecrane.co/"
                className="cursor-pointer text-white font-semibold duration-300 text-base border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]"
              >
                <li onClick={closeMobileMenu}>Blog</li>
              </a>

              {/* <a href="mailto:support@usecrane.co">
              <li
                onClick={closeMobileMenu}
                className="cursor-pointer text-white font-semibold duration-300 text-base border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]"
              > Contact Us
              </li>
              </a> */}

              <Link
                to="/privacy-policy"
                className="cursor-pointer text-white font-semibold duration-300 text-base border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]"
              >
                <li onClick={closeMobileMenu}>Privacy Policy</li>
              </Link>

              <Link
                to="/termsandconditions"
                className="cursor-pointer text-white font-semibold duration-300 text-base border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]"
              >
                <li onClick={closeMobileMenu}>Terms & Conditions</li>
              </Link>

              <Link
                to="/aml"
                className="cursor-pointer text-white font-semibold duration-300 text-base border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]"
              >
                <li onClick={closeMobileMenu}>AML</li>
              </Link>

              <Link
                to="/account"
                className="cursor-pointer text-white font-semibold duration-300 text-base border-b border-gray-50 border-opacity-[10%] w-full pb-[24px]"
              >
                <li onClick={closeMobileMenu}>Account</li>
              </Link>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavbarButtons;
