import { motion } from "framer-motion";
import { cardsData } from "../../constants";
import Feature from "../Feature";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ThirdSection = () => {
  return (
    <div
      // variants={staggerContainer}
      // initial="hidden"
      // whileInView="show"
      // viewport={{ once: false, amount: 0.25 }}
      className="bg-white pt-9 lg:pt-18 flex flex-col items-center justify-center text-center"
    >
      <Feature
        title=""
        subTitle="Crypto to cash in 3 easy steps"
        longText="We handle the heavy lifting so you can relax. We help you conveniently convert crypto to your local currency and have it land exactly where you need it—your bank account."
        maxWidth="606px"
        whiteSpace="normal"
      />

      {/*play store buttons  */}
      <div className="flex gap-3 items-center mt-[40px]">
        <motion.a
          href="https://play.google.com/store/apps/details?id=com.crane.bigbird"
          target="_blank"
          rel="noreferrer"
          aria-label="download the app on play store"
          className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
          whileHover={{
            scale: 1.1,
          }}
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706015436/Google_Play_2x_wjrqnd.png"
            alt="google-play-icon"
            title="google-play-icon"
            className="w-full h-full"
          />
        </motion.a>

        <motion.a
          href="https://apps.apple.com/us/app/crane-app/id6502242343"
          target="_blank"
          rel="noreferrer"
          aria-label="download the app on ios"
          className="w-[106px] h-[36px] lg:w-[141px] lg:h-[48px] object-contain cursor-pointer"
          whileHover={{
            scale: 1.1,
          }}
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706013834/App_Store_2x_okviuw.png"
            alt="app-store-icon"
            title="app-store-icon"
            className="w-full h-full"
          />
        </motion.a>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 mx-auto items-center justify-evenly place-items-center w-11/12 lg:w-[4/5] lg:mt-[76px] mt-[40px]">
        {cardsData.map((world) => (
          <div
            key={world.id}
            // variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
            className="flex flex-1 flex-col max-w-[412px]"
          >
            <div className="flex flex-col">
              <div className="flex flex-row justify-start  items-start gap-4">
                <div className="hidden md:block w-[28px] h-[28px]">
                  <LazyLoadImage
                    src={world.imgUrl}
                    alt="trade-icon"
                    title="trade-icon"
                    className=" w-full h-full"
                  />
                </div>
                <div className="md:hidden block w-[20px] h-[20px]  ">
                  <LazyLoadImage
                    src={world.imgUrl}
                    alt="trade-icon"
                    title="trade-icon"
                    className=" w-full h-full "
                  />
                </div>
                <div className="md:text-2xl text-base font-bold text-left md:text-center">
                  {world.title}
                </div>
              </div>

              <div className="text-left pt-5 md:pt-8 text-sm md:text-xl pb-[36px]">
                {world.subTitle}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThirdSection;
