import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/modal/Modal";
import FirstSection from "../components/autoConvertComp/FirstSection";
import SecondSection from "../components/autoConvertComp/SecondSection";
import ThirdSection from "../components/autoConvertComp/ThirdSection";
import Footer from "../components/footer/Footer";

const AutoConvert = () => {
  const [isOpen, setIsOpen] = useState(false);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
      <title> Auto-convert Your Crypto Assets</title>
        <meta
          name="title"
          content=" 
          Auto-convert Your Crypto Assets"
        />
        <meta
          property="og:title"
          content="
          Auto-convert Your Crypto Assets"
        />
        <link rel="canonical" href="https://usecrane.co/auto-convert" />
        <meta
          name="description"
          content="Enjoy easy global transactions and effortlessly receive payments in Naira, Shillings and Cedis in 3 steps using Crane App"
        />
        <meta
          property="og:description"
          content="Enjoy easy global transactions and effortlessly receive payments in Naira, Shillings and Cedis in 3 steps using Crane App"
        />
      </Helmet>
      <Navbar
        bgColor="[#FFF]"
        textColor="[#050B05]"
        openModal={openModal}
      />
      <Modal onClose={closeModal} isOpen={isOpen} />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <Footer />
    </>
  );
};

export default AutoConvert;
