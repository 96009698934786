import { LazyLoadImage } from "react-lazy-load-image-component";
import QrCodeAndDownloadButtons from "../qrCodeAndDownloadButtons/QrCodeAndDownloadButtons";
// import spinningGlobe from "../../assets/lottie/Small Spinning Globe.json";
// import LottieAnimation from "../LottieAnimation";

const FirstSection = () => {
  const className =
    "flex lg:items-start lg:justify-start items-center justify-center gap-[24px] xl:gap-[15px]";
  return (
    <div className="">
      <div className="bg-[#FFF] justify-between items-center lg:flex ">
        <div className=" flex justify-center 2xl:justify-start lg:w-1/2 mx-auto lg:ml-[5%] xl:ml-[5%]">
          <div className="flex-col px-8 self-center justify-around gap-10 lg:pb-[130px] pb-[62px]">
            <div className=" flex flex-row justify-center items-center gap-2 pt-[3.5rem] lg:pt-[7rem] lg:justify-start lg:text-left">
              <div className=" lg:w-[16px] lg:h-[16px] w-[12px] h-[12px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014489/Check_oyamc9.svg"
                  alt="check-icon"
                  title="check-icon"
                  className="w-full h-full"
                />
              </div>

              <div className="flex flex-col text-left justify-start text-[#525155] text-xs lg:text-base">
                Bank Direct
              </div>
            </div>

            <h1 className="text-[#050B05] max-w-[670px] capitalize font-extrabold text-[1.75rem] lg:text-[3rem] xl:text-[4rem] leading-tight pt-6 text-center lg:text-left pb-[24px] xl:pb-[40px]">
              Work&nbsp;
              <span className="inline-block text-[#1AD05D] italic">
                Globally,
              </span>
              <br /> Spend&nbsp;
              <span className="inline-block text-[#1AD05D] italic">
                Locally
              </span>
            </h1>

            <div className="text-[#050B05] max-w-[722px] font-medium text-sm lg:text-xl pb-6 lg:pt-5 xl:pt-10 pb-[40px] lg:pb-[64px] text-center lg:text-left leading-relaxed">
              Get paid in crypto and receive as Naira in 2 minutes with Bank
              Direct. Enjoy instant conversions and direct bank transfers—making
              access to your money easier than ever
            </div>

            <QrCodeAndDownloadButtons className={className} />
          </div>
        </div>

        <div className="lg:h-[600px]  2xl:h-[680px] sm:h-[450px] md:h-[500px] h-[320px] w-[100%] mx-auto lg:w-[867px] relative">
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1728390695/Illustrations-Hero_Image_2x_rj67lf.png"
            alt="A vibrant 3D illustration of a pink classical bank with floating golden bitcoins and a cloud, symbolizing the seamless connection between traditional finance and global cryptocurrency payments through Bank Direct."
            title="A vibrant 3D illustration of a pink classical bank with floating golden bitcoins and a cloud, symbolizing the seamless connection between traditional finance and global cryptocurrency payments through Bank Direct."
            className="w-full h-full"
          />

          {/* <div className="spinningGlobe  w-[130px] h-[100px] absolute  top-[-10px] lg:top-0 lg:right-[16%] md:right-[23%] xl:right-[22%] 2xl:right-[23%] right-[20%] sm:right-[17%] md:w-[200px] xl:w-[190px] 2xl:w-[200px] xl:h-[190px] ">
            <LottieAnimation animationData={spinningGlobe} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
