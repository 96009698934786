import React, { useEffect, useState } from "react";
import LottieAnimation from "../LottieAnimation";
import SpinningGlobe from "../../assets/lottie/Spinning Globe.json";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SecondSection = () => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // Example: Trigger animation when user scrolls to a certain position
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setTrigger(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className=" bg-[#F2F4F8] flex justify-between flex-col items-center overflow-hidden px-[17px]  pt-[44px] xl:pt-[120px] xl:gap-[120px] gap-[60px] ">
      <div className="flex justify-start items-center gap-[40px] text-center flex-col md:w-[844px] flex-grow">
        <h3 className=" leading-tight text-[#050B05] md:font-bold font-extraboldtext-center text-[1.75rem] md:text-[2.5rem] lg:text-[4rem]">
          Receive payment as a freelancer from anywhere in the world!
        </h3>
        <p className="text-[#050B05] font-medium text-sm text-center md:text-xl leading-relaxed">
          The Instant convert feature on Crane allows you to cash out in your
          local currency in under 100 seconds. As a freelancer, the
          opportunities to do business globally are limitless, but when it comes
          to getting paid, international transactions can be a nightmare. From
          service delays to hidden fees, and complex conversions eating into
          your hard-earned income. Crane is your next best alternative.
        </p>
      </div>
      <div class="flex flex-grow h-[50%] xl:h-full"></div>

      {/* <div className="  h-[350px] xl:h-[500px] w-[321px] xl:w-[600px] mt-auto relative">

        <img
          src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1709643506/Receive_Phone_Mockup_pylw2l.svg"
          alt="instant conversion"
           title="instant conversion"
          className="w-full h-full z-50 flex"
          // loading="lazy"
        />

        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10  overflow-hidden xl:h-[50%] 2xl:h-[65%] w-full hidden xl:block flex-grow">
          <LottieAnimation animationData={SpinningGlobe} />
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10 overflow-hidden  w-[1200px] h-full  block xl:hidden flex-grow">
          <LottieAnimation animationData={SpinningGlobe} />
        </div>
      </div> */}
      <div className="relative">
        <div className="h-[300px] xl:h-[550px] w-[321px] xl:w-[600px] mt-auto">
          <LazyLoadImage
            src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1709643506/Receive_Phone_Mockup_pylw2l.svg"
            alt="Smartphone showing Bitcoin wallet QR code with network selection"
            title="Smartphone showing Bitcoin wallet QR code with network selection"
            className="w-full h-full relative z-50"
          />
        </div>

        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10 overflow-hidden h-full w-[1600px]  hidden xl:block">
          <LottieAnimation animationData={SpinningGlobe} trigger={trigger}/>
        </div>

        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10 overflow-hidden w-[1200px] h-full block xl:hidden flex-grow">
          <LottieAnimation animationData={SpinningGlobe} trigger={trigger}/>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
