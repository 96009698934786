

import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../../utils/motion";
import QrCodeAndDownloadButtons from "../qrCodeAndDownloadButtons/QrCodeAndDownloadButtons";

const MakeYourMoney = () => {
  const className = "flex items-start justify-start gap-[24px] xl:gap-[15px]";
  return (
    <motion.div
    className="mx-auto w-11/12 xl:mb-[100px] mb-[63px] flex justify-start xl:justify-start items-start xl:items-end gap-[24px] xl:gap-[31px] xl:flex-row flex-col"
    variants={staggerContainer}
    initial="hidden"
    whileInView="show"
    viewport={{ once: false, amount: 0.25 }}
  >
    <p className="text-[#050B05] text-5xl xl:text-[7rem] capitalize pt-[40px] lg:pt-[88px]">
      <motion.span
        className="text-[#525155]"
        variants={fadeIn("left", "tween", 0.2, 1)}
      >
        Make Your <br />
      </motion.span>
      Money
      <motion.span
        className="text-[#525155] "
        variants={fadeIn("left", "tween", 0.5, 1.3)}
      >
        &nbsp;Move
      </motion.span>
    </p>

    <QrCodeAndDownloadButtons className={className}/>
    </motion.div>
  )
}

export default MakeYourMoney