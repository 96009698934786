import React, { useState, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Slider = () => {
  // eslint-disable-next-line no-unused-vars
  const [activeCard, setActiveCard] = useState(0);
  const [cards] = useState([
    {
      title: "Freelancers",
      content:
        "Seamlessly receive global payments directly into your local bank account in 2 minutes, no delays.",
    },
    {
      title: "Business Owners",
      content:
        "Generate payment links to share with your international clients and receive value instantly. Withdraw up to 10 million naira daily.",
    },
    {
      title: "Crypto Enthusiast",
      content:
        "New to crypto? We got you! Crane’s user-friendly app makes receiving and converting crypto a breeze.",
    },
  ]);

  // Define an array of background colors
  const backgroundColors = ["bg-[#fff]", "bg-[#050B05]", "bg-[#F2F4F8]"];
  const opacity = ["bg-opacity-0", "bg-opacity-40", "bg-opacity-0"];
  const border = ["[#EEECF6]", "[0]", "[#F2F4F8]"];

  const color = ["text-[#050B05]", "text-[#fff]", "text-[#050B05]"];

  const images = [
    "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016309/Illustration-Freelancers_2x_lzigac.png",
    "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706017523/Widget-Generate_Link_2x_htqaal.png",
    "https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706016380/Illustration-Phone_2x_diyid2.png",
  ];
  const alt = [
    "Golden cryptocurrency coins hovering over a green globe, representing global cryptocurrency transactions",
    "Image showing how to generate payment link",
    "Smartphone showing Bitcoin selling preview with dollar value and exchange rate.",
  ];
  const backgroundImages = [
    null, // No background image for the first card
    'url("https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014216/Business_Photo_2x_xnjfbe.png")', // Background image for the second card
    null, // No background image for the third card
  ];

  useEffect(() => {
    // Automatically slide to the next card
    const interval = setInterval(() => {
      setActiveCard((prevActiveCard) =>
        prevActiveCard === cards.length - 1 ? 0 : prevActiveCard + 1
      );
    }, 3000); // Change the interval as needed (e.g., 5000ms for 5 seconds)

    return () => clearInterval(interval);
  }, [cards]);

  // INTERJECTION OBSERVER FOR THE SLIDER INDICATOR ON MOBILE
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsRef = useRef([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  const handleIntersect = (entries, observer) => {
    entries.forEach((entry, index) => {
      if (entry.isIntersecting) {
        // console.log('Card is fully in view');
        // console.log('Card index:', index);
        setCurrentIndex(index); // Update the current index based on the card in view
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, options);

    cardsRef.current.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      observer.disconnect();
    };
  }, [options]);

  return (
    <>
      <div className="hidden xl:flex  justify-center text-left items-start gap-[28px] p-4 mt-[150px]">
        <div className="flex justify-between">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`h-[720px] max-h-[720px] rounded-[32px] w-[30%]  transform transition
               ${backgroundColors[index]} border shadow border-${border[index]} relative bg-cover bg-center bg-no-repeat hover:scale-110`}
              style={{
                backgroundImage: backgroundImages[index], // Set the background image
                backgroundSize: "cover",
              }}
            >
              <div className="pt-[52px] z-[100] relative">
                <h2
                  className={` px-[52px] lg:text-[1.75rem] ${color[index]} font-semibold  text-[1.25rem] pb-[24px]`}
                >
                  {card.title}
                </h2>
                <p
                  className={`px-[52px] lg:text-[1.5rem] ${color[index]} text-[0.75rem] font-medium`}
                >
                  {card.content}
                </p>
              </div>
              <div
                className={`absolute ${index === 0 ? "custom-card-1" : ""} ${
                  index === 1 ? "custom-card-2" : ""
                } ${index === 2 ? "custom-card-3" : ""}`}
              >
                <LazyLoadImage
                  src={images[index]}
                  alt={alt[index]}
                  title={alt[index]}
                  className=" w-full h-full "
                />
              </div>

              <div
                className={`absolute top-0 right-0 bottom-0 left-0 bg-black rounded-[32px]  ${opacity[index]} z-0`}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile View (100%) */}
      <div className="lg:hidden overflow-x-scroll w-full slider">
        <div className=" mt-[60px] w-[1000px] lg:mt-[72px] lg:mb-[46px] mb-[8px]  mx-auto gap-[20px] flex justify-center items-center">
          {cards.map((card, index) => (
            <div
              key={index}
              ref={(ref) => (cardsRef.current[index] = ref)}
              className={`h-[384px] max-h-[384px] max-w-[320px] w-[310px] rounded-[32px]
              } ${backgroundColors[index]} border shadow border-${border[index]} relative bg-cover bg-center bg-no-repeat card  `}
              style={{
                backgroundImage: backgroundImages[index], // Set the background image
                backgroundSize: "cover",
              }}
            >
              <div
                className="pt-[32px] z-[100] relative"
                // style={{ zIndex: "100", position: "relative" }}
              >
                <h2
                  className={` px-[32px] lg:text-[1.75rem] ${color[index]} font-semibold  text-[1.25rem] pb-[12px]`}
                >
                  {card.title}
                </h2>
                <p
                  className={`px-[32px] lg:text-[1.5rem] ${color[index]} text-[0.75rem] font-medium`}
                >
                  {card.content}
                </p>
              </div>
              <div
                className={`absolute ${
                  index === 0 ? "mobile-custom-card-1" : ""
                } ${index === 1 ? "mobile-custom-card-2" : ""} ${
                  index === 2 ? "mobile-custom-card-3" : ""
                }`}
              >
                <LazyLoadImage
                  src={images[index]}
                  alt={alt[index]}
                  title={alt[index]}
                  className=" w-full h-full "
                />
              </div>

              <div
                className={`absolute top-0 right-0 bottom-0 left-0 bg-black rounded-[32px]  ${opacity[index]} z-0`}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {/* INDICATOR */}
      <div className="flex xl:hidden justify-center gap-[20px] mt-[32px] mb-[48px]">
        {cards.map((_, index) => (
          <div
            key={index}
            className={`w-[8px] h-[8px] rounded-full ${
              currentIndex === index ? "bg-[#14F195]" : "bg-[#D5D5D5]"
            }`}
          ></div>
        ))}
      </div>
    </>
  );
};

export default Slider;
