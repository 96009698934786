/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ExchangeRate = () => {
  const [inputtedAmount, setInputtedAmount] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [clickedCoinsValue, setClickedCoinsValue] = useState("usdc");
  const [clickedCurrencyValue, setClickedCurrencyValue] = useState("ngn");
  // const [ngnRate, setNgnRate] = useState("");
  const [convertedNgnRate, setConvertedNgnRate] = useState("");
  const [correspondingPrice, setCorrespondingPrice] = useState(null);
  const [correspondingUsdNgnRate, setCorrespondingUsdNgnRate] = useState(null);
  const [correspondingGhsNgnRate, setCorrespondingGhsNgnRate] = useState(null);
  const [correspondingKesNgnRate, setCorrespondingKesNgnRate] = useState(null);
  const [isOpenCoins, setIsOpenCoins] = useState(false);
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [response, setResponse] = useState(null); // Declare response here

  // Function to process data from the API response
  const processDataFromAPI = (data) => {
    for (const item of data) {
      if (item.currency.startsWith(clickedCoinsValue)) {
        setCorrespondingPrice(
          parseFloat(item.price) || parseFloat(item.usd_price)
        );
        setCorrespondingUsdNgnRate(parseFloat(item.usd_ngn_price));

        setCorrespondingKesNgnRate(parseFloat(item.kes_ngn_price));

        setCorrespondingGhsNgnRate(parseFloat(item.ghs_ngn_price));

        break; // Exit the loop once a match is found
      }
    }

    if (
      !isNaN(correspondingPrice) &&
      !isNaN(
        correspondingUsdNgnRate ||
          correspondingKesNgnRate ||
          correspondingGhsNgnRate
      )
    ) {
      calculateConversion();
    }
    // if (!isNaN(correspondingPrice) && !isNaN(correspondingUsdNgnRate)) {
    //   calculateConversion();
    // }
  };

  // Fetch data from the API and call processDataFromAPI
  const fetchData = async () => {
    // setIsLoading(true);

    try {
      const apiResponse = await axios.get(
        "https://api.usecrane.co/v1/crypto/coins/rates"
      );
      // setIsLoading(false);
      setResponse(apiResponse); // Store the response in state
      processDataFromAPI(apiResponse.data.data);
    } catch (error) {
      console.error(error);
      // setIsLoading(false);
    }
  };

  // Calculate the conversion based on inputtedAmount
  const calculateConversion = () => {
    let convertedNgnRate;

    if (clickedCurrencyValue === "ngn") {
      convertedNgnRate =
        correspondingPrice * inputtedAmount * correspondingUsdNgnRate;
    } else if (clickedCurrencyValue === "kes") {
      convertedNgnRate =
        correspondingPrice *
        inputtedAmount *
        correspondingKesNgnRate *
        correspondingUsdNgnRate;
    } else if (clickedCurrencyValue === "ghs") {
      convertedNgnRate =
        correspondingPrice *
        inputtedAmount *
        correspondingGhsNgnRate *
        correspondingUsdNgnRate;
    } else {
      convertedNgnRate = correspondingPrice * inputtedAmount;
    }

    setConvertedNgnRate(convertedNgnRate.toLocaleString());
  };

  // const handleSetInputtedAmount = e => {
  //   let amount = e.target.value;

  //   // Remove any characters that are not digits or dots
  //   amount = amount.replace(/[^\d.]/g, '');

  //   // Use a regular expression to ensure it has a maximum of 8 decimal places
  //   if (/^\d*\.?\d{0,8}$/.test(amount)) {
  //     setInputtedAmount(amount);
  //   }
  // };

  const handleSetInputtedAmount = (e) => {
    let amount = e.target.value;

    // Remove any characters that are not digits or dots
    amount = amount.replace(/[^\d.]/g, "");

    // Ensure it has a maximum of 8 decimal places
    if (/^\d*\.?\d{0,8}$/.test(amount)) {
      // Ensure the value does not exceed 9999999
      // const parsedAmount = parseFloat(amount);
      if (!isNaN(amount) && amount >= 0 && amount <= 9999999) {
        // Format the number with commas
        // const formattedAmount = parsedAmount.toLocaleString('en-US');

        // // Set the formatted amount back to the input field
        // e.target.value = formattedAmount;

        // Set the state or perform further actions with the parsed amount
        setInputtedAmount(amount);
      }
    }
  };

  // Call the API and calculate conversion when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (response?.data) {
      processDataFromAPI(response.data.data);
    }
  }, [clickedCoinsValue]);

  // Use another useEffect to run fetchData every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
      setClickedCoinsValue("usdc");
      setInputtedAmount("");
    }, 180000); // 180000 milliseconds = 3 minute

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // Call calculateConversion when inputtedAmount, clickedCoinsValue, or clickedCurrencyValue changes
  useEffect(() => {
    calculateConversion();
  }, [inputtedAmount, clickedCoinsValue, clickedCurrencyValue]);

  // get default rate on page load and on coinvalue change
  const defaultPrice = correspondingPrice * 1 * correspondingUsdNgnRate;

  const toggleCoinsDropdown = () => {
    setIsOpenCoins(!isOpenCoins);
    setIsCurrencyOpen(false);
  };
  const toggleCurrencyDropdown = () => {
    setIsCurrencyOpen(!isCurrencyOpen);
    setIsOpenCoins(false);
  };

  const handleCoinsClick = (value) => {
    // Update the state with the clicked coin value
    setClickedCoinsValue(value);
    setConvertedNgnRate("");
    setInputtedAmount("");
    setIsOpenCoins(false);
  };
  const handleCurrencyClick = (value) => {
    // Update the state with the clicked currency value
    setClickedCurrencyValue(value);
    setConvertedNgnRate("");
    setIsCurrencyOpen(false);
  };

  return (
    <div
      // variants={fadeIn("up", "tween", 0.3, 1)}
      className="flex flex-col justify-center items-center px-4 md:px-20 py-11  mt-12 mb-10 rounded-xl border-2 md:my-20 md:w-[682px] border-[#050B05] w-11/12 md:mx-auto mx-4"
    >
      <div className="mb-8 md:mb-11 rounded-full bg-white text-center py-2 px-4 md:px-5 text-xs md:text-2xl font-bold flex gap-3 md:gap-6 justify-center items-center">
        <div className="  xl:w-[32px] xl:h-[32px] w-[24px] h-[24px]">
          <LazyLoadImage
            src={`/image/${clickedCoinsValue}.jpg`}
            alt="coin-img"
            title="coin-img"
            className=" w-full h-full "
          />
        </div>
        {/*   src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014979/Element_kvsyzb.svg" */}
        {`1 ${clickedCoinsValue.toUpperCase()} = ${defaultPrice.toLocaleString()} NGN`}
      </div>
      <div className="items-center w-full self-center">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col md:gap-4 gap-2">
            <label
              htmlFor="inputtedAmount"
              onClick={toggleCoinsDropdown}
              className="text-left md:text-xl text-sm  font-bold flex items-center  gap-[8px] cursor-pointer "
            >
              <div className="w-[28px] h-[28px] xl:w-[40px] xl:h-[40px]">
                <LazyLoadImage
                  src={`/image/${clickedCoinsValue}.jpg`}
                  alt="coin-img"
                  title="coin-img"
                  className="w-full h-full"
                />
              </div>

              {clickedCoinsValue && clickedCoinsValue.toUpperCase()}
              <div className="xl:w-[32px] xl:h-[32px] w-[24px] h-[24px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014827/Dropdown_xhwq0g.svg"
                  alt="dropdown-img"
                  title="dropdown-img"
                  className="w-full h-full"
                />
              </div>
            </label>
            <div className="relative border-[1px] border-[#050B05] rounded-xl p-5 flex items-center justify-start bg-white h-[48px] md:h-[64px]">
              <input
                value={inputtedAmount}
                onChange={handleSetInputtedAmount}
                // onKeyDown={handleInputAmountChange}
                type="number"
                name="inputtedAmount"
                id="inputtedAmount"
                step="0.00000001"
                pattern="[0-9.]+"
                required
                autoComplete="none"
                className=" focus:outline-none w-full font-bold md:text-xl text-sm  appearance-none "
              />
              {/* <span className="text-[#707070] md:text-xl text-sm  font-medium">
                BTC
              </span> */}

              {/* <div
                onClick={toggleCoinsDropdown}
                className="absolute right-[24px] lg:right-[16px] gap-[20px] flex justify-center items-center cursor-pointer"
              >
                <span className="text-[#050B05] md:text-xl text-sm  font-bold">
                 
                  {clickedCoinsValue && clickedCoinsValue.toUpperCase()}
                </span>
                <div className=" w-[24px] h-[24px] lg:w-[32px] lg:h-[32px]">
                  <img
                    src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014827/Dropdown_xhwq0g.svg"
                      alt="dropdown-icon"
                          title="dropdown-icon"
                    className="w-full h-full"
                    // loading="lazy"
                  />
                </div>
              </div> */}

              {isOpenCoins && (
                <div className=" bg-[white] w-[152px] absolute bottom-[-309px] lg:bottom-[-350px] border left-0 z-50 drop-shadow-[#0000001F] rounded-[4px]">
                  <ul className="divide-y  divide-light-blue-400 ">
                    {/* <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold p-[20px] hover:bg-[#EAFEF5]"
                      onClick={() => handleCoinsClick("usd")}
                    >
                      USD
                    </li> */}
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold p-[20px] hover:bg-[#EAFEF5]"
                      onClick={() => handleCoinsClick("btc")}
                    >
                      BTC
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold  p-[20px] hover:bg-[#EAFEF5]   "
                      onClick={() => handleCoinsClick("eth")}
                    >
                      ETH
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold  p-[20px] hover:bg-[#EAFEF5]  "
                      onClick={() => handleCoinsClick("usdc")}
                    >
                      USDC
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold p-[20px] hover:bg-[#EAFEF5]  "
                      onClick={() => handleCoinsClick("trx")}
                    >
                      TRX
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold p-[20px] hover:bg-[#EAFEF5]  "
                      onClick={() => handleCoinsClick("doge")}
                    >
                      DOGE
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col md:gap-4 gap-2">
            <label
              htmlFor="convertedNgnRate"
              className="text-left md:text-xl text-sm  font-bold flex items-center gap-[8px] cursor-pointer"
              onClick={toggleCurrencyDropdown}
            >
              <div className=" xl:w-[40px] xl:h-[40px] w-[28px] h-[28px]">
                <LazyLoadImage
                  src={`/image/${clickedCurrencyValue}.jpg`}
                  alt="clicked-currency-icon"
                  title="clicked-currency-icon"
                  className="w-full h-full"
                />
              </div>

              {clickedCurrencyValue && clickedCurrencyValue.toUpperCase()}
              <div className="xl:w-[32px] xl:h-[32px] w-[24px] h-[24px]">
                <LazyLoadImage
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014827/Dropdown_xhwq0g.svg"
                  alt="dropdown-icon"
                  title="dropdown-icon"
                  className="w-full h-full"
                />
              </div>
            </label>

            <div className="relative border-[1px] border-[#050B05] rounded-xl p-5 flex items-center justify-start bg-white h-[48px] md:h-[64px]">
              <input
                value={convertedNgnRate}
                disabled
                autoComplete="none"
                name="convertedNgnRate"
                className=" focus:outline-none w-full font-bold md:text-xl text-sm text-black bg-transparent"
              />

              {/* <div
               
                className="absolute right-[24px] lg:right-[16px] gap-[20px] flex justify-center items-center cursor-pointer"
              >
                <span className="text-[#050B05] md:text-xl text-sm  font-bold">
                  NGN
                
                </span>
                <div className=" w-[24px] h-[24px] lg:w-[32px] lg:h-[32px]">
                  <img
                    src="https://res.cloudinary.com/dpqxraalv/image/upload/f_webp,f_auto,q_auto/v1706014827/Dropdown_xhwq0g.svg"
                    alt="dropdown-icon"
                       title="dropdown-icon"
                    className="w-full h-full"
                    // loading="lazy"
                  />
                </div>
              </div> */}
              {/* <span className="text-[#707070] md:text-xl text-sm  font-medium">
                NGN
              </span> */}

              {isCurrencyOpen && (
                <div className=" bg-[white] w-[152px] absolute bottom-[-248px] lg:bottom-[-280px]  border left-0 z-50 drop-shadow-[#0000001F] rounded-[4px]">
                  {/*bottom-[-126px] lg:bottom-[-143px]  */}
                  <ul className="divide-y  divide-light-blue-400 ">
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold p-[20px] hover:bg-[#EAFEF5]"
                      onClick={() => handleCurrencyClick("ngn")}
                    >
                      NGN
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold  p-[20px] hover:bg-[#EAFEF5]   "
                      onClick={() => handleCurrencyClick("usd")}
                    >
                      USD
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold  p-[20px] hover:bg-[#EAFEF5]   "
                      onClick={() => handleCurrencyClick("kes")}
                    >
                      KES
                    </li>
                    <li
                      className=" cursor-pointer lg:text-xl text-sm font-semibold  p-[20px] hover:bg-[#EAFEF5]   "
                      onClick={() => handleCurrencyClick("ghs")}
                    >
                      GHS
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-5 md:pt-8 pt-5 w-full">
            <a
              href="https://crane.onelink.me/QQEs/zz9a92a7"
              className="bg-[black] rounded-xl p-5 flex font-bold md:text-base text-sm cursor-pointer items-center justify-center gap-5 text-white"
            >
              <motion.button
                // onClick={doConversion}
                // disabled={isButtonDisabled}
                whileHover={{
                  scale: 1.1,
                }}
              >
                {/* {isLoading
                ? "Performing our magic 😊"
                : "  Convert your crypto in seconds"} */}
                Convert your crypto in seconds
              </motion.button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeRate;
